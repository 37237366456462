@charset "utf-8";
/*======================================
[ -Main Stylesheet-
Theme: Towhub
Version: 1.5
Last change: 15/05/2020 ]
/*-------------Fonts---------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Quicksand:wght@300..700&display=swap');
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,700,800,900|Roboto:400,500,700,900&amp;display=swap");
/*-------------General Style---------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

/* 
p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3b4249;
} */

html {
  overflow-x: hidden !important;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer !important;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 13px;
  height: 100%;
  text-align: center;
  background: #2e3f6e;
  color: #000;
  font-family: "Roboto";
}



@-o-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

/*--------------Typography--------------------------------------*/
blockquote {
  float: left;
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 15px solid #eee;
  position: relative;
}

blockquote:before {
  font-family: Font Awesome\5 Pro;
  content: "\f10e";
  position: absolute;
  color: #ccc;
  bottom: 3px;
  font-size: 43px;
  right: 6px;
  opacity: 0.4;
}


p {
  font-family: "Quicksand", sans-serif !important;
  font-size: 14px !important;
  color: rgb(59, 66, 73);
  line-height: 24px !important;
}

blockquote p {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  color: #494949;
}

/*------ loader-------------------------------------------------*/
.loader-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #2e3f6e;
  -webkit-transform: translate3d(0, 0, 0);
}

.loader-inner {
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 32px;
  animation: counterClockwise 4s linear infinite;
  margin: -16px 0 0 -16px;
  border: 2px solid;
  border-radius: 100%;
}

.loader-inner:before,
.loader-inner:after {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.loader-inner:before {
  width: 8px;
  height: 8px;
  background: #fff;
  animation: clockwise 4s linear infinite;
  top: -4px;
}

.loader-inner:after {
  width: 4px;
  height: 4px;
  top: 4px;
  left: 22px;
  background-color: #4db7fe;
  transform-origin: -4px -4px;
  animation: counterClockwise 1s linear infinite;
}

.loader-9::before,
.loader-9::after {
  border-radius: 50%;
}

@-webkit-keyframes clockwise {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes clockwise {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes counterClockwise {
  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

@keyframes counterClockwise {
  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

/* ---------Content Styles--------------------------------------*/
#main {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

.big-container {
  padding-top: 100px;
  /* max-width: 1500px; */
}

.small-container {
  max-width: 1024px;
}

#wrapper {
  /* margin-top: 100px; */
  float: left;
  width: 100%;
  position: relative;
  z-index: 25;
  padding-top: 80px;
}

.content {
  width: 100%;
  position: relative;
  z-index: 4;
  float: left;
  padding-top: 100px;
  background: #fff;
}



.geodir-js-favorite_btn2 {
  /* position: absolute; */
  /* left: 20px; */
  padding-top: 7px;
  /* top: 20px; */
  z-index: 20;
  cursor: pointer;
  color: #fff;
}

.geodir-js-favorite_btn2 i {
  float: left;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 100%;
  background: rgb(175, 35, 34);
  position: relative;
  z-index: 2;
}

.geodir-js-favorite_btn2 span {
  border-radius: 36px;
  background: rgb(175, 35, 34);
  float: left;
  left: -36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  padding: 0 18px 0 48px;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  margin-top: -2px;
  visibility: hidden;
}

.geodir-js-favorite_btn2 span {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.geodir-js-favorite_btn2 i {
  font-weight: 900;
}

.fl-wrap {
  float: left;
  width: 100%;
  position: relative;
}

.no-bg {
  background: none;
}

.full-height {
  height: 100%;
}

.full-width-wrap {
  width: 100%;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-attachment: scroll;
  background-position: top;
  background-repeat: repeat;

  background-origin: content-box;
}

.respimg {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.2;
  z-index: 2;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.bg-gray {
  background-color: #F7F8F9;
}

.bg-green {
  background-color: #8EC639 !important;
}

.op1 {
  opacity: 0.1;
}

.op3 {
  opacity: 0.3;
}

.op4 {
  opacity: 0.4;
}

.op7 {
  opacity: 0.5 !important;
}

.dark-bg {
  background: #464b61;
}

.dark-gray-bg {
  background: #464b61;
}

/*-------------Button---------------------------------------*/
.btn {
  padding: 12px 55px 12px 25px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  background-color: rgb(175, 35, 34);
  overflow: hidden;
  transition: all 200ms linear;
}

.btn i {
  position: absolute;
  right: 10px;
  top: 52%;
  height: 20px;
  width: 20px;
  color: white !important;
  border-radius: 100%;
  line-height: 20px;
  margin-top: -10px;
  transition: all 200ms linear;
}

.btn.dec_btn {
  padding: 15px 55px 15px 25px;
  border-radius: 4px;
}

.btn.dec_btn:before {
  content: "";
  position: absolute;
  /* left: -8px;
  right: -8px; */
  top: -8px;
  bottom: -8px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: rgb(175, 35, 34);
  z-index: -1;
}

.btn:hover i {
  color: #fff !important;
  right: 0px !important;
  box-shadow: 0px 0px 0px 24px rgba(255, 255, 255, 0.1);
}

.btn.border-btn {
  border: 2px solid;
}

.btn.color-bg,
.btn.border-btn:hover,
.btn.gradient-bg {
  color: #fff;
}

.float-btn {
  float: left;
  margin-top: 10px;
}

/*-------------accordion---------------------------------------*/
.accordion {
  float: left;
  width: 100%;
  position: relative;
}

.accordion a.toggle {
  border: 1px solid #eee;
  padding: 16px 50px 16px 30px;
  float: left;
  width: 100%;
  position: relative;
  background: #fff;
  border-radius: 4px 4px;
  box-sizing: border-box;
  text-align: left;
  font-size: 13px;
  font-weight: 700;
  color: #666;
  transition: all 300ms linear;
  margin-bottom: 15px;
}

.accordion a.toggle span {
  position: absolute;
  right: -1px;
  top: -1px;
  bottom: -1px;
  width: 60px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.accordion a.toggle span:before,
.accordion a.toggle span:after {
  content: "";
  position: absolute;
  background: #fff;
  top: 50%;
  left: 50%;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.accordion a.toggle span:before {
  width: 10px;
  height: 2px;
  margin-top: -1px;
  margin-left: -5px;
}

.accordion a.toggle span:after {
  width: 2px;
  height: 10px;
  margin-top: -5px;
  margin-left: -1px;
}

.accordion a.toggle.act-accordion {
  color: #fff;
  border-color: transparent;
}

.accordion a.toggle.act-accordion span:after {
  height: 0;
}

.accordion-inner {
  display: none;
  float: left;
  width: 100%;
  position: relative;
  padding: 20px 30px 15px;
  background: #fff;
  margin: 0 0 15px;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #eee;
}

.accordion-inner.visible {
  display: block;
}

/* ------Header------------------------------------------------------------ */
.main-header {
  position: fixed;
  width: 100%;
  left: 0;
  /* top: 0; */
  height: 90px;
  background: white;
  z-index: 31;
  border-bottom: 1px solid #d8dce2 !important;
display: flex;
  padding: 0 40px;
  -webkit-transform: translate3d(0, 0, 0);
}

.main-header:before {
  content: "";
  position: absolute;
  right: 0;
  width: 480px;
  height: 100%;
  bottom: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  background: white;
}

.dsh-header.main-header:before {
  width: 540px;
}

.main-header.vis-searchdec:before {
  opacity: 0;
}

.logo-holder {
  float: left;
  height: 30px;
  position: relative;
  top: 5px;
}

.logo-holder img {
  width: auto;
  height: 100%;
}

.header-search_btn {
  float: left;
  margin-left: 70px;
  width: 180px;
  padding: 14px 0;
  border-radius: 30px;
  position: relative;
  color: #fff;
  top: 18px;
  font-weight: 500;
  font-size: 13px;
  background: #3d528b;
  cursor: pointer;
}

.header-search_btn span {
  position: relative;
}

.header-search_btn span:before {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  height: 10px;
  margin-top: -5px;
  width: 1px;
  background: rgba(255, 255, 255, 0.31);
}

.header-search_btn i {
  margin-right: 30px;
}

.add-list {
  float: right;
  padding: 0 22px;
  position: relative;
  height: 40px;
  top: 20px;
  line-height: 40px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.1);
}

.add-list:hover {
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0);
}

.add-list span {
  color: #fff;
  padding-left: 10px;
}

.cart-btn {
  float: right;
  position: relative;
  color: #fff;
  cursor: pointer;
  margin-right: 34px;
  top: 28px;
  font-size: 18px;
}

.cart-btn i {
  width: 12px;
}

.myst {
  position: fixed;
  z-index: 9999;
  width: 100%;
}

.header-upper {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #d8dce2 !important;
  height: 70px;
  background-color: white;
  padding: 12px;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .header-upper {
    width: 1024px;
    display: flex;
    border-bottom: 1px solid #d8dce2 !important;
    height: 100px;
    background-color: white;
    padding: 12px;
    justify-content: space-between;
  }

}


.cart-counter {
  position: absolute;
  bottom: -1px;
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 100%;
  color: #fff;
  left: -5px;
  z-index: 2;
  font-size: 8px;
  font-weight: 400;
}

.show-reg-form {
  float: right;
  color: #fff;
  cursor: pointer;
  margin-right: 40px;
  position: relative;
  top: 32px;
  font-weight: 500;
  font-size: 12px;
}

.show-reg-form:before {
  content: "";
  position: absolute;
  left: -25px;
  top: 50%;
  height: 20px;
  margin-top: -10px;
  width: 1px;
  background: rgba(255, 255, 255, 0.31);
}

.show-reg-form i {
  margin-right: 14px;
}

.lang-wrap {
  float: right;
  margin-right: 20px;
  position: relative;
  top: 20px;
}

.lang-tooltip {
  position: absolute;
  top: 40px;
  right: 0;
  width: 100px;
  z-index: 105;
  background: #fff;
  padding: 10px 0 12px;
  border-radius: 4px;
  border: 1px solid #eee;
  border-top: none;
  visibility: hidden;
  opacity: 0;
}

.show-lang {
  color: #fff;
  cursor: pointer;
  width: 100px;
  float: right;
  padding: 10px 10px 10px 0;
  position: relative;
  z-index: 106;
  font-size: 12px;
  border: 1px solid transparent;
}

.show-lang i.arrlan {
  position: absolute;
  left: 70px;
  top: 50%;
  line-height: 10px;
  height: 10px;
  margin-top: -5px;
  color: #fff;
}

.show-lang span {
  position: relative;
  z-index: 2;
  float: left;
  margin-left: 10px;
}

.show-lang span strong {
  padding-left: 10px;
  top: -2px;
  font-weight: 500;
  position: relative;
}

.show-lang span i {
  font-size: 17px;
}

.show-lang .fa-angle-down {
  position: relative;
  top: 2px;
}

.lang-wrap:hover .lang-tooltip {
  visibility: visible;
  opacity: 1;
}

.no-list-style {
  list-style: none;
}

.lang-tooltip li {
  float: left;
  width: 100%;
  position: relative;
  text-align: left;
  color: #fff;
  border-radius: 4px;
  padding: 6px 14px;
}

.lang-tooltip li a {
  color: #566985;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  position: relative;
  float: left;
  width: 100%;
}

.lang-tooltip li a:before {
  font-family: Font Awesome\5 Pro;
  content: "\f00c";
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}

.lang-tooltip li a.current-lan:before {
  opacity: 1;
}

.header-user-menu {
  float: right;
  position: relative;
  top: 20px;
  margin-left: 50px;
  margin-right: 40px;
}

.header-user-menu:after {
  content: "";
  position: absolute;
  left: -70px;
  height: 30px;
  top: 4px;
  width: 1px;
  background: rgba(255, 255, 255, 0.21);
}

.header-user-name {
  position: relative;
  float: left;
  cursor: pointer;
  color: #3B4249;
  transition: 0.2s;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 10px;
  font-weight: 600;
}

.header-user-name:before {
  /* font-family: Font Awesome\5 Pro; */
  /* content: "\f0d7"; */
  position: absolute;
  color: #fff;
  bottom: 1px;
  font-size: 13px;
  right: -16px;
  transition: all 0.2s ease-in-out;
}

.header-user-name span {
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  overflow: hidden;
  top: 0;
  left: -50px;
}

.header-user-name span img {
  width: 100%;
  height: 100%;
}

.header-user-menu ul {
  margin: 10px 0 0 0;
  opacity: 0;
  list-style: none;
  visibility: hidden;
  position: absolute;
  min-width: 150px;
  top: 60px;
  left: -50px;
  z-index: 99;
  padding: 10px 0;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  transition: all 0.2s ease-in-out;
}

.header-user-name.hu-menu-visdec:before {
  transform: rotateX(180deg);
}

.header-user-menu ul.hu-menu-vis {
  opacity: 1;
  visibility: visible;
  top: 40px;
  height: 70vh;
  overflow-y: scroll;
}

.header-user-menu ul li {
  float: left;
  width: 100%;
  padding: 4px 0;
}

.header-user-menu ul li a {
  color: #50596e;
  float: left;
  width: 100%;
  font-weight: 500;
  text-align: left;
  padding: 6px 15px;
}

/* ------Navigation------------------------------------------------------------ */
.nav-holder {
  display: flex;
  justify-content: center;
  width: 100%;
  float: left;
  position: relative;
  /* top: 18px; */
  /* margin-left: -6px; */
  top: 20px;
  opacity: 1;
  right: 0;
  visibility: visible;
  z-index: 20;
}

.nav-holder nav {
  position: relative;
  float: right;
}

.nav-holder nav li {
  float: left;
  position: relative;
  margin-left: 6px;
  height: 50px;
}

.nav-holder nav li ul {
  margin: 30px 0 0 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  min-width: 150px;
  top: 50px;
  left: 0;
  z-index: 1;
  padding: 10px 0;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  transition: all 0.2s ease-in-out;
  list-style: none;
}

.nav-holder nav li:hover>ul {
  opacity: 1;
  visibility: visible;
  margin: 0;

  /* color: white; */
}

.nav-holder nav li ul li ul {
  top: -10px;
  left: 95%;
  margin-left: 25px;
  margin-top: 0;
  max-width: 150px;
  list-style: none;
}

.nav-holder nav li ul li:hover>ul {
  opacity: 1;
  visibility: visible;
  margin-right: 0px;
}

.nav-holder nav li ul li {
  width: 100%;
  float: left;
  height: auto;
  position: relative;
}

.nav-holder nav li a {
  float: left;
  padding: 10px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  text-transform: none;
  line-height: 20px;
  letter-spacing: 0px;
  color: rgb(59, 66, 73);
  transition: all 100ms linear;
}

.nav-holder nav li a:hover {
  color: #af2322;
}

.nav-holder nav li a i {
  padding-left: 6px;
  transition: all 200ms linear;
}

.nav-holder nav li a:hover i {
  transform: rotateX(180deg);
}

.nav-holder nav li ul a {
  color: #566985;
  float: left;
  width: 100%;
  font-weight: 500;
  text-align: left;
  padding: 5px 15px;
}

nav li ul a:before,
nav li ul a:after {
  display: none;
}

.nav-button-wrap {
  float: right;
  height: 36px;
  width: 36px;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  top: 24px;
  margin-right: 16px;
  display: none;
}

.nav-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 20px;
  margin-left: -11px;
  margin-top: -6px;
}

.nav-button span {
  float: left;
  width: 100%;
  height: 2px;
  background: #fff;
  margin-bottom: 4px;
  border-radius: 4px;
  transition: all 200ms linear;
}

.nav-button-wrap.vismobmenu_btn span {
  display: none;
}

.nav-button-wrap.vismobmenu_btn:before {
  font-family: Font Awesome\5 Pro;
  content: "\f00d";
  position: absolute;
  color: #fff;
  font-size: 18px;
  top: 8px;
  left: 12px;
  font-weight: 100;
}

.header-search_container {
  position: absolute;
  bottom: -120px;
  left: 0;
  width: 100%;
  padding: 30px 0;
  background: #3d528b;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms linear;
}

.header-search_container:before {
  font-family: Font Awesome\5 Pro;
  content: "\f689";
  position: absolute;
  left: 30px;
  top: 30px;
  color: rgba(255, 255, 255, 0.11);
  font-size: 54px;
}

.vis-head-search {
  opacity: 1;
  visibility: visible;
  bottom: -110px;
}

.header-search-input {
  width: 32%;
  float: left;
  padding: 0 4px;
  position: relative;
}

.header-search-input.header-search_selectinpt {
  float: left;
  width: 36%;
  padding: 0 4px;
}

.header-search-input input:focus {
  border-radius: 4px 4px 0 0;
}

.header-search-input input::-webkit-input-placeholder {
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}

.header-search-input .nice-select {
  background: rgba(255, 255, 255, 0.11);
  height: 50px;
  line-height: 50px;
  border: none;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
}

.header-search-input .nice-select input::-webkit-input-placeholder {
  color: #7d93b2;
}

.header-search-input .nice-select input:focus {
  border-color: #eee;
}

.header-search-input .nice-select .option {
  color: #7d93b2;
}

.location a {
  position: absolute;
  right: 20px;
  height: 20px;
  line-height: 20px;
  top: 50%;
  margin-top: -10px;
  z-index: 3;
  color: #fff;
  font-size: 10px;
}

.header-search-input-wrap {
  padding: 0 199px 0 0;
}

.header-search-input label {
  position: absolute;
  left: 20px;
  top: 18px;
  z-index: 10;
  font-size: 14px;
}

.header-search-input input {
  float: left;
  border: none;
  background: rgba(255, 255, 255, 0.11);
  border-radius: 4px;
  position: relative;
  height: 50px;
  padding: 0 20px 0 40px;
  z-index: 1;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
}

.header-search-button {
  position: absolute;
  right: 0;
  height: 50px;
  top: 0;
  line-height: 50px;
  border: none;
  color: #fff;
  border-radius: 30px;
  width: 180px;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.1);
  transition: all 300ms linear;
}

.header-search_container .header-search-button:hover {
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.1);
}

.header-search-button i {
  padding-right: 12px;
}

.header-search_container .header-search-button:hover i {
  color: #fff;
}

.nice-select .option.selected {
  color: #fff;
}

.header-search_close {
  position: absolute;
  right: 50%;
  margin-right: -18px;
  top: 100%;
  margin-top: 30px;
  width: 46px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  z-index: 2;
  font-size: 15px;
  cursor: pointer;
  border-radius: 0 0 4px 4px;
}

.scrollbar-inner .simplebar-scrollbar:before {
  background: rgba(0, 0, 0, 0.2);
  width: 5px;
}

.header-modal {
  position: absolute;
  top: 100%;
  width: 400px;
  z-index: -1;
  transition: all 300ms linear;
  right: 190px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  opacity: 0;
  visibility: hidden;
  display: none;
}

.vis-wishlist {
  opacity: 1;
  visibility: visible;
  right: 210px;
  display: block;
}

.show-search-button i.vis-head-search-close:before,
.show-header-modal.scwllink i:before {
  font-family: Font Awesome\5 Pro;
  content: "\f00d";
}

.show-header-modal.scwllink .cart-counter {
  opacity: 0;
}

.header-modal-top {
  padding: 15px 60px 15px 20px;
  background: #425998;
  border-radius: 0 0 6px 6px;
}

.header-modal-top h4 {
  float: left;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
}

.header-modal-top h4 span {
  font-size: 11px;
  font-weight: 400;
  padding-left: 4px;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  top: 0px;
}

.header-modal-top h4 span strong {
  padding: 0 2px;
}

.header-modal-container {
  max-height: 320px;
  overflow: auto;
  padding: 10px 20px;
  background: #fff;
}

.close-header-modal {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  border-left: 1px solid rgba(255, 255, 255, 0.11);
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  font-size: 17px;
  color: #54a9fc;
}

.header-modal_btn i {
  padding-left: 12px;
}

.main-register-holder .tabs-menu li {
  float: left;
  width: 50%;
}

.tab-content {
  width: 100%;
  display: none;
  float: left;
}

.tab {
  width: 100%;
  float: left;
}


.error-msg-color {
  color: #3b4249;
}

/* .main-register-holder .tab-content {
  padding: 20px 0 5px;
} */

.first-tab {
  display: block;
}

.widget-posts li {
  padding: 10px 0;
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid #eee;
  transition: all 100ms linear;
}

.widget-posts li:hover {
  background: #f5f5f5;
}

.widget-posts-img {
  float: left;
  width: 30%;
  position: relative;
}

.widget-posts-descr {
  float: left;
  width: 70%;
  padding: 0 20px;
}

.widget-posts-img img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.widget-posts-descr h4 {
  text-align: left;
  font-weight: 600;
  font-size: 13px;
  padding-bottom: 2px;
}

.widget-posts-descr h4 a {
  color: #566985;
}

.widget-posts-descr-score {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #18458b;
  font-weight: 600;
  color: #fff;
  border-radius: 4px 0 0 0;
  font-size: 10px;
  opacity: 0;
  border: 1px solid rgba(0, 0, 0, 0.21);
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.21);
}

.widget-posts li:hover .widget-posts-descr-score,
.widget-posts-descr-score.wpds-visible {
  opacity: 1;
}

.widget-posts-descr .geodir-category-location a {
  float: left;
  text-align: left;
  color: #999;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
}

.geodir-category-location a i {
  padding-right: 4px;
}

.widget-posts-descr .geodir-category-location {
  margin-top: 4px;
}

.widget-posts-descr-link {
  float: left;
  width: 100%;
  margin-top: 10px;
}

.widget-posts-descr-link a {
  float: left;
  margin-right: 4px;
  text-align: left;
  color: #7d93b2;
  font-size: 11px;
  font-weight: 600;
}

.clear-wishlist {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  font-size: 16px;
  visibility: hidden;
  opacity: 0;
}

.widget-posts li:hover .clear-wishlist {
  opacity: 1;
  visibility: visible;
}

.listing-rating i {
  color: #facc39;
}

.listing-rating.small-font i {
  font-size: 10px;
  margin-right: 2px;
}

.widget-posts-descr .listing-rating {
  float: left;
}

/*------ section-------------------------------------------------*/
section {
  float: left;
  position: relative;
  padding: 20px 70px;
  background: #fff;
  z-index: 1;
  width: 100%;
}

.specific-ul {
  overflow-y: scroll;
  height: 60vh;
  padding: 10px;
  margin: 0;
  list-style-type: none;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 #f1f1f1;
  /* For Firefox */
}

.specific-ul::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

.specific-ul::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
  border-radius: 10px;
  /* Rounded corners */
}

.specific-ul::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners */
  border: 3px solid #f1f1f1;
  /* Border around the thumb */
}

.specific-ul::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color when hovered */
}


.imp {
  font-family: "Quicksand", sans-serif !important;
  color: #3a424a;
}

button {
  font-family: "Quicksand" !important;
  border: none;
}

input {
  font-family: "Quicksand" !important;
  background-color: white !important;
}

span {
  font-family: "Quicksand" !important;
}

section.parallax-section {
  padding: 110px 0;
  overflow: hidden;
}

.color-inner {
  background-color: white !important;
}

section.filter-section {
  padding: 110px 0 0;
}

section.video-section {
  padding: 150px 0;
  overflow: hidden;
}

.small-padding {
  padding: 50px 0;
}

.no-top-padding-sec {
  padding-top: 0;
}

section.parallax-section.small-par {
  padding: 90px 0;
}

section.hero-section {
  padding: 100px 0 100px;
  z-index: 20;
}

.slw-sec {
  padding: 80px 0 60px;
}

.gray-bg {
  background: white;
}

section.hero-section .bg {
  height: 120%;
  top: -10%;
}

section.parallax-section .bg {
  height: 120%;
  top: 0;
}

.section-title {
  float: left;
  width: 100%;
  position: relative;
  padding-bottom: 30px;
}

.parallax-section .section-title {
  padding-bottom: 0;
}

.section-title .breadcrumbs {
  margin-top: 20px;
}

.section-title .breadcrumbs a,
.section-title .breadcrumbs span {
  color: rgba(255, 255, 255, 0.71);
  display: inline-block;
  padding: 0 16px;
  position: relative;
  font-weight: 500;
}

.section-title .breadcrumbs span,
.section-title .breadcrumbs a:hover {
  color: #fff;
}

section.color-bg .section-title .breadcrumbs {
  margin-top: 0;
  margin-bottom: 10px;
}

section.color-bg .section-title .breadcrumbs a:before {
  /* background: #fff !important; */
  color: #3B4249;
  background-color: #8ec639;
}

.section-title p {
  /* color: #7d93b2; */
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px;
  max-width: 550px;
  margin: 0 auto;
}

.section-title h2 {
  float: left;
  width: 100%;
  text-align: center;
  /* color: #697891; */
  font-size: 30px;
  font-weight: 700;
  position: relative;
}

.parallax-section .section-title h2 {
  font-size: 32px;
}

.parallax-section .section-title h4 {
  color: #fff;
  font-weight: 400;
}

.section-separator {
  float: left;
  width: 100%;
  position: relative;
  margin: 20px 0;
}

section.gray-section .section-separator {
  background: #f9f9f9;
}

.header-sec-link {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  bottom: 0;
}

.header-sec-link a {
  position: absolute;
  left: 50%;
  width: 220px;
  height: 80px;
  line-height: 138px;
  bottom: 0;
  margin-left: -110px;
  overflow: hidden;
}

.header-sec-link a i {
  position: relative;
  z-index: 2;
  bottom: 0;
  font-size: 19px;
}

.header-sec-link a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 400px 58px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA3NiAzMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzYgMzE7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjx0aXRsZT5iZ19zb3VyY2VfYmFkZ2VfMTwvdGl0bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTcuNywxMS40Yy0xLjQtMS40LTIuNy0yLjktNC4xLTQuNGMtMC4yLTAuMy0wLjUtMC41LTAuOC0wLjhjLTAuMi0wLjItMC4zLTAuMy0wLjUtMC41bDAsMA0KCUM0OC42LDIuMiw0My41LDAsMzgsMFMyNy40LDIuMiwyMy42LDUuN2wwLDBjLTAuMiwwLjItMC4zLDAuMy0wLjUsMC41Yy0wLjMsMC4zLTAuNSwwLjUtMC44LDAuOGMtMS40LDEuNS0yLjcsMy00LjEsNC40DQoJYy01LDUuMS0xMS43LDYuMS0xOC4zLDYuM1YzMWg5LjRoOC45aDM5LjRoNC45SDc2VjE3LjZDNjkuNCwxNy40LDYyLjcsMTYuNSw1Ny43LDExLjR6Ii8+DQo8L3N2Zz4NCg==);
  top: 25px;
  left: 0;
  z-index: -1;
}

.section-separator:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  height: 3px;
  margin-left: -25px;
  width: 50px;
  border-radius: 3px;
  z-index: 2;
}

.section-separator.fl-sec-sep:before {
  left: 0;
  margin-left: 0;
}

.parallax-section .section-title h2,
.parallax-section .section-title p {
  color: #fff;
}

.parallax-section .section-title.left-align,
.parallax-section .section-title.left-align h2 {
  text-align: left;
}

.section-subtitle {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: -10px;
  font-size: 64px;
  opacity: 0.18;
  z-index: -1;
  letter-spacing: -2px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "Raleway", sans-serif;
}

.parallax-section .section-subtitle {
  color: #fff;
  opacity: 0.2;
}

.section-subtitle:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 0.95) 100%);
}

.gray-section .section-subtitle:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, rgba(249, 249, 249, 249)),
      color-stop(100%, rgba(249, 249, 249, 0.75)));
  background: -webkit-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(249, 249, 249, 0.75) 100%);
  background: -o-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(249, 249, 249, 0.75) 100%);
  background: -ms-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(249, 249, 249, 0.75) 100%);
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(249, 249, 249, 0.75) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fffff', GradientType=0);
}

.parallax-section .section-subtitle:before {
  display: none;
}

.hero-slider-item {
  padding: 90px 0 190px;
}

.shb {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  z-index: 20;
  border-radius: 100%;
  cursor: pointer;
  color: #fff;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.4);
  transition: all 400ms linear;
}

.shb:hover {
  background: #fff;
}

.shb.slider-hero-button-prev {
  left: 20px;
}

.shb.slider-hero-button-next {
  right: 20px;
}

.hero-slider-item .nice-select .list {
  max-height: 150px;
}

.hero-categories {
  margin-top: 50px;
  z-index: 1;
}

.hero-categories li {
  display: inline-block;
  padding: 0 30px;
}

.hero-categories li a {
  display: block;
  color: rgba(255, 255, 255, 0.71);
}

.hero-categories li a i {
  display: block;
  font-size: 34px;
  margin-bottom: 10px;
}

.hero-categories li a:hover i,
.hero-categories li a:hover {
  color: #fff;
}

.hero-categories_title {
  color: rgba(255, 255, 255, 0.9);
  margin: 20px 0 30px;
  position: relative;
  display: block;
  font-weight: 500;
  display: inline-table;
}

.hero-categories_title:before {
  content: "";
  position: absolute;
  right: -180px;
  top: 0;
  width: 60px;
  height: 60px;
  /* background: url(../images/arrow.png) no-repeat center; */
}

.intro-item h1 {
  font-size: 46px;
  line-height: 70px;
  font-weight: 900;
  color: #fff;
  font-family: "Raleway", sans-serif;
  position: relative;
  z-index: 2;
}

.individual-bubble {
  position: absolute;
  border-radius: 100%;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
}

.bubbles {
  display: inline-table;
  position: relative;
  padding: 12px 0;
}

.intro-item h3 {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.mob_bg {
  display: none;
}

.main-search-input-tabs .tabs-container,
.hero-slider-item .hero-search {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.2);
}

.custom-placeholder::placeholder {
  font-family: "Quicksand" !important;
  color: #3b4249 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  /* Optional: change the color of the placeholder text */

}

/* .main-search-input-tabs .tabs-container,
.hero-slider-item .hero-search,
.hero-map-search-wrap .hero-search {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.2);
} */

.hero-slider-item .hero-search {
  margin-top: 40px;
}

.hero-map {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.hero-map-search-wrap {
  z-index: 2;
  margin-top: -100px;
  padding: 0 50px;
}

.main-search-input-tabs .tabs-menu {
  z-index: 2;
  position: relative;
  margin-bottom: 15px;
}

.main-search-input-tabs .tabs-menu li {
  display: inline-block;
  position: relative;
}

.main-search-input-tabs .tabs-menu li a {
  padding: 12px 20px;
  display: block;
  border-radius: 4px 4px 0 0;
  color: #fff;
  font-weight: 600;
  position: relative;
  letter-spacing: 2px;
  box-sizing: border-box;
  font-size: 11px;
  text-transform: uppercase;
}

.main-search-input-tabs .tabs-menu li a:before {
  top: 30px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-bottom-color: #fff;
  border-width: 14px;
  margin-left: -14px;
  display: none;
}

.main-search-input-tabs .tabs-menu li.current a:before {
  display: block;
}

.main-search-input-tabs {
  margin-top: 50px;
}



.main-search-input-item {
  float: left;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  padding: 0 5px;
  position: relative;
}

.gm-style-mtc {
  max-width: 200px !important;
  /* Adjust this value to your desired width */
}



.main-search-input-item input {
  font-weight: 800;
  border: 1px solid #e5e7f2;
  width: 158px;
  height: 50px;
  padding-left: 10px;
  font-size: 13px;
  border-radius: 4px;
}

.main-search-input-item label {
  position: absolute;
  left: 20px;
  top: 17px;
  z-index: 10;
}

.main-search-button {
  width: 100%;
  height: 100%;
  color: #fff;
  top: 10px;
  /* border-radius: 6px;/ */
  border: none;
  cursor: pointer;
}

.main-search-button i {
  padding-left: 10px;
  color: #fff !important;
}

.main-search-input-item .nice-select {
  color: #666;
  font-size: 13px;
  font-weight: 800;
  float: left;
  width: 100%;
  border: 1px solid #e5e7f2;
  height: 50px;
  z-index: 100;
  background: #fff;
  line-height: 50px;
}

.main-search-input-wrap.illust-item {
  max-width: 900px;
  margin: 20px auto;
  position: relative;
}

p {
  font-family: Quicksand !important;
}

.main-search-input:before {
  content: "";
  position: absolute;
  bottom: -40px;
  width: 50px;
  height: 1px;
  background: rgba(255, 255, 255, 0.41);
  left: 50%;
  margin-left: -25px;
}

.main-search-input-item.location a {
  position: absolute;
  top: 18px;
  right: 22px;
  z-index: 500;
}

.main-search-input-item.location a:hover {
  border-radius: 100%;
  animation: pulse 1.1s infinite ease-in-out;
}

.daterangepicker_big .daterangepicker {
  width: 506px !important;
  max-width: none;
  top: 100px;
  left: 20px;
}

.input-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.input-field {
  width: 100px;
  /* Adjust as needed */
  padding-right: 30px;
  /* Adjust padding to make space for the icons */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  -moz-appearance: textfield;
  /* Remove default number input arrows for Firefox */
}

.input-field::-webkit-outer-spin-button,
.input-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 5px;
  /* Adjust position as needed */
  top: 50%;
  transform: translateY(-50%);
}

.custom-buttons-second {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  /* Adjust position as needed */
  top: 50%;
  transform: translateY(-50%);
}

.custom-buttons button {
  background: none;
  border: none;
  font-size: 12px;
  /* Adjust size as needed */
  cursor: pointer;
  padding: 2px;
  line-height: 1;
  user-select: none;
}

.custom-buttons .increment {
  margin-bottom: -6px;
}

.custom-buttons .decrement {
  margin-top: 2px;
}


.bg_tabs {
  transition: all 400ms linear;
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }

  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }

  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

.waveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%;
  z-index: -1;
}

.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -45px;
}

.bgMiddle {
  z-index: 10;
}

.bgBottom {
  z-index: 5;
}

.wave-bg-anim {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}

.waveTop {
  background-size: 50% 100px;
}

.waveAnimation .waveTop {
  animation: move-wave 3s;

  animation-delay: 1s;
}

.waveMiddle {
  background-size: 50% 100px;
}

.waveAnimation .waveMiddle {
  animation: move_wave 9s linear infinite;
}

.waveBottom {
  background-size: 50% 90px;
}

.waveAnimation .waveBottom {
  animation: move_wave 14s linear infinite;
}

/*------ register form------------------------------------------------*/
.main-register-wrap,
.reg-overlay,
.map-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  display: none;
  -webkit-transform: translate3d(0, 0, 0);
  background: rgba(37, 44, 65, 0.7);
}

.map-overlay {
  z-index: 2000;
}

.reg-overlay {
  z-index: 1;
  background: none;
}

.hid-body {
  overflow: hidden;
}

.main-register-holder {
  max-width: 420px;
  margin: 100px auto 50px;
  z-index: -1;
}

.main-register {
  float: left;
  width: 100%;
  position: relative;
  padding: 80px 0 20px;
  margin-bottom: 50px;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.1);
}

.modal_main {
  transition: all 0.4s ease-in-out;
  transform: scale(0.6);
  opacity: 0;
}

.modal_main.vis_mr {
  opacity: 1;
  transform: scale(1);
}

.close-reg {
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
  height: 54px;
  line-height: 54px;
  cursor: pointer;
  z-index: 3;
  color: #fff;
  border-left: 1px solid rgba(255, 255, 255, 0.11);
  font-size: 18px;
}

.main-register_title {
  position: absolute;
  left: 0;
  top: 0;
  height: 54px;
  line-height: 54px;
  padding-left: 30px;
  color: #fff;
  text-align: left;
  background: #4e65a3;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  z-index: 2;
}

.main-register_title span {
  text-transform: uppercase;
  font-weight: 800;
}

.soc-log {
  padding: 15px 0;
}

.soc-log p,
.lost-password-tootip p {
  margin-bottom: 12px;
  font-size: 12px;
  color: #7d93b2;
}

.soc-log a {
  float: left;
  width: 100%;
  color: #fff;
  height: 50px;
  line-height: 50px;
  margin-bottom: 10px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  text-align: center;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.soc-log a.facebook-log {
  background: #3b5998;
}

.soc-log a.twitter-log {
  background: #55acee;
}

.soc-log a.paypal-log {
  background: #205da5;
}

.log-separator span {
  position: relative;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #eee;
  border-radius: 100%;
  display: inline-block;
  background: #f5f6fa;
  font-size: 10px;
  text-transform: uppercase;
  z-index: 2;
  color: #7d93b2;
}

.log-separator:before,
.log-separator:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 50%;
  height: 1px;
  background: #eee;
  z-index: 1;
}

.log-separator:after {
  left: inherit;
  right: 0;
}

.custom-form .log-submit-btn i {
  margin-left: 10px;
}

.main-register .filter-tags {
  float: left;
  margin-top: 20px;
}

.main-register .filter-tags.ft-list {
  margin-top: 2px;
}

.lost_password {
  margin-top: 24px;
  float: right;
}

.lost_password a,
.main-register-holder .filter-tags {
  float: left;
  color: #7d93b2;
  font-size: 12px;
  font-weight: 600;
}

.main-register-holder .tabs-container {
  float: left;
  width: 100%;
  margin-top: 10px;
  padding: 0 30px;
}

.main-register-holder .tabs-menu {
  padding: 0 30px;
}

.main-register-holder .tabs-menu:before,
.filter-sidebar-header .tabs-menu:before {
  content: "";
  position: absolute;
  left: 30px;
  right: 30px;
  height: 1px;
  bottom: 1px;
  background: #e5e5e5;
}

.filter-sidebar-header .tabs-menu:before {
  left: 0;
  right: 0;
}

.main-register-holder .tabs-menu li,
.filter-sidebar-header .tabs-menu li {
  float: left;
  text-align: left;
  z-index: 2;
  border-bottom: 3px solid transparent;
}

.filter-sidebar-header .tabs-menu li {
  text-align: center;
}

.main-register-holder .tabs-menu li a,
.filter-sidebar-header .tabs-menu li a {
  color: #7d93b2;
  font-size: 13px;
  display: block;
  font-weight: 600;
  padding: 14px 0;
}

.main-register-holder .tabs-menu li a i,
.filter-sidebar-header .tabs-menu li a i {
  margin-right: 10px;
}

.main-register-holder .custom-form .filter-tags label {
  font-size: 11px;
  color: #7d93b2;
}

.main-register-holder .custom-form .btn {
  margin-top: 0;
}

.wave {
  opacity: 0.9;
  position: absolute;
  top: 3%;
  left: 50%;
  background: #475897;
  width: 500px;
  height: 500px;
  margin-left: -250px;
  margin-top: -250px;
  transform-origin: 50% 48%;
  border-radius: 43%;
  animation: drift 6000ms infinite linear;
}

.wave.-three {
  animation: drift 10000ms infinite linear;
}

.wave.-two {
  animation: drift 14000ms infinite linear;
  opacity: 0.4;
  background: #79bcfc;
}

.wave-bg {
  position: absolute;
  bottom: -400px;
  height: 220px;
  left: 60px;
  width: 100%;
  z-index: -1;
  transform: rotateY(10deg);
}

@-webkit-keyframes drift {
  from {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}

@keyframes drift {
  from {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}

.login-column {
  float: left;
  position: relative;
  padding-top: 50px;
}

.login-column .main-register-holder {
  margin-top: 0;
  max-width: 550px;
}

.login-column .main-register {
  padding-top: 0;
}

.login-column-bg {
  position: fixed;
  top: 0;
  right: 0;
  left: 650px;
  overflow: hidden;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
}

.login-column_header {
  max-width: 500px;
  margin: 0 auto 50px;
}

.login-column_header img {
  height: 30px;
  width: auto;
  margin: 0 auto;
}

.login-column_header h4 {
  font-size: 24px;
  color: #3b5998;
  margin-top: 20px;
}

.login-promo-container {
  position: absolute;
  left: 0;
  width: 100%;
  top: 40%;
  z-index: 20;
}

.lost-password {
  position: relative;
}

.lost-password-tootip {
  position: absolute;
  right: 0;
  width: 350px;
  bottom: 60px;
  padding: 30px 20px;
  background: #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.15);
  transition: all 200ms linear;
  opacity: 0;
  visibility: hidden;
}

.lpt_vis {
  opacity: 1;
  visibility: visible;
}

.lost-password-tootip p {
  text-align: left;
}

.close-lpt {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 12px;
  cursor: pointer;
}

/*-------------Forms---------------------------------------*/
.custom-form {
  float: left;
  width: 100%;
  position: relative;
}

.custom-form textarea,
.custom-form input[type="text"],
.custom-form input[type="email"],
.custom-form input[type="number"],
.custom-form input[type="password"],
.custom-form input[type="button"],
.listsearch-input-item input[type="text"] {
  float: left;
  border: 1px solid #e5e7f2;
  background: #f9f9f9;
  width: 100%;
  padding: 13px 20px 13px 45px;
  border-radius: 0px;
  color: #7d93b2;
  font-size: 15px;
  -webkit-appearance: none;
  outline: none;
  overflow: hidden;
  z-index: 1;
}

.custom-form.dark-form textarea,
.custom-form.dark-form input[type="text"],
.custom-form.dark-form input[type="email"],
.custom-form.dark-form input[type="password"],
.custom-form.dark-form input[type="button"] {
  border: none;
  background: rgba(255, 255, 255, 0.08);
}

.custom-form textarea::-webkit-input-placeholder,
.custom-form input[type="text"]::-webkit-input-placeholder,
.listsearch-input-item input[type="text"]::-webkit-input-placeholder {
  color: #7d93b2;
  font-weight: 500;
  font-size: 12px;
  font-family: "Roboto";
}

.custom-form textarea:focus,
.custom-form input[type="text"]:focus,
.custom-form input[type="email"]:focus,
.custom-form input[type="password"]:focus,
.listsearch-input-item input[type="text"]:focus {
  background: #fff;
  box-shadow: 0px 10px 14px 0px rgba(12, 0, 46, 0.06);
}

.custom-form .nice-select input {
  padding-left: 20px;
}

.custom-form textarea {
  height: 100px;
  resize: none;
  padding: 10px 10px;
  -webkit-appearance: none;
}

.custom-form input {
  margin-bottom: 0px;
}

.filter-tags input,
.custom-form .filter-tags input {
  float: left;
  position: relative;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 2px;
  color: #000;
  background: #fff;
  -webkit-appearance: none;
}

.custom-form.dark-form .filter-tags input {
  border-color: transparent;
  background: rgba(255, 255, 255, 0.21);
}

.filter-tags input:checked:after,
.custom-form .filter-tags input:checked:after {
  font-family: Font Awesome\5 Pro;
  content: "\f00c";
  font-size: 12px;
  position: absolute;
  top: 2px;
  left: 3px;
  z-index: 20;
}

.filter-tags label,
.custom-form .filter-tags label {
  float: left;
  padding: 0 10px;
  position: relative;
  top: 4px;
  color: #888da0;
  font-weight: 600;
  width: auto;
  font-size: 11px;
}

.custom-form.dark-form .filter-tags label {
  color: #fff;
}

.custom-form label {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: #666;
  color: #878c9f;
  font-size: 13px;
  font-weight: 500;
}

.main-register .custom-form label {
  padding-bottom: 12px;
}

.custom-form.dark-form label {
  color: rgba(255, 255, 255, 0.71);
}

.custom-form label i {
  padding-right: 12px;
  font-size: 14px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.vis-label {
  margin-bottom: 10px;
  font-size: 12px;
}

.custom-form label.vis-label i {
  top: 44px;
}

.custom-form button {
  outline: none;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 20px;
}

.custom-form input[type="number"]::-webkit-inner-spin-button,
.custom-form input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-form input[type="number"] {
  -moz-appearance: textfield;
}

.custom-form .quantity span {
  float: left;
  position: relative;
  top: 16px;
  padding-left: 14px;
  color: #7d93b2;
}

.custom-form .quantity span i {
  padding-right: 10px;
  font-size: 14px;
}

.custom-form .quantity input.minus,
.custom-form .quantity input.plus,
.custom-form .quantity input.qty {
  width: 50px;
  height: 50px;
  border-radius: 0;
  text-align: center;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
}

.custom-form .quantity input.plus {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.custom-form .quantity input.minus {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.quantity-item {
  float: right;
}

.custom-form .quantity input.qty {
  color: #fff;
  width: 50px;
  border-right: none;
  border-left: none;
  cursor: default;
  -webkit-appearance: none;
  background: #4e65a3;
}

.custom-form.no-icons input,
.custom-form.no-icons textarea {
  padding-left: 10px;
}

.custom-form.no-icons label {
  margin-bottom: 20px;
}

.pass-input-wrap span {
  position: absolute;
  right: 20px;
  cursor: pointer;
  bottom: 36px;
  z-index: 10;
  font-size: 12px;
}

.pass-input-wrap input {
  padding-left: 10px !important;
}

.radio {
  color: #999;
  font-size: 13px;
  position: relative;
  top: -2px;
}

.radio span {
  position: relative;
  padding-left: 30px;
  top: 1px;
}

.radio span:after {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  position: absolute;
  left: 0;
  top: -3px;
  box-sizing: border-box;
}

.radio input[type="radio"] {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.radio input[type="radio"]:checked+span {
  color: #666;
}

.radio input[type="radio"]:checked+span:before {
  font-family: Font Awesome\5 Pro;
  content: "\f00c";
  font-size: 13px;
  position: absolute;
  top: 0;
  left: 4px;
  z-index: 20;
}

.book-form .quantity-item label {
  width: auto;
  position: relative;
  top: 16px;
  padding-right: 10px;
  color: #878c9f;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.book-form .quantity-item label i {
  position: relative;
  float: left;
  top: 0;
  left: 0;
  padding-right: 8px;
}

.book-form .quantity {
  float: right;
}

.book-form .quantity-item {
  margin-bottom: 20px;
  float: left;
  width: 50%;
}

.book-form .quantity-item.fcit {
  padding-left: 20px;
}

.book-form .btnaplly {
  color: #fff;
  width: 100%;
  background: #f8bb11;
  padding: 15px 0;
  border-radius: 4px;
}

.book-form .btnaplly i {
  padding-left: 10px;
}

.total-coast {
  padding: 15px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}

.total-coast strong {
  color: #878c9f;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 21px;
}

.total-coast span {
  float: right;
  text-align: right;
  font-size: 19px;
  font-weight: 400;
  position: relative;
  top: 3px;
}

.custom-form .total-coast input {
  width: inherit !important;
  float: left;
  border: none;
  background: none;
  height: auto;
  padding: 0;
  color: #888da0;
  font-size: 24px;
  text-align: right;
  margin-bottom: 0;
  top: -3px;
  position: relative;
  right: 10px;
}

.hid-input {
  display: none;
}

.onoffswitch {
  position: relative;
  width: 83px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 21px;
  background-color: #4db7fe;
  color: #fff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 21px;
  background-color: #4db7fe;
  color: #fff;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 21px;
  margin: 4.5px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 49px;
  border: 2px solid #ffffff;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.main-register .custom-form input[type="text"],
.main-register .custom-form input[type="email"],
.main-register .custom-form input[type="password"] {
  padding-left: 20px;
}

.circle-wrap {
  position: absolute;
  transition: all 300ms linear;
}

.circle_bg-bal {
  display: block;
  background: radial-gradient(circle at 10px 10px,
      rgba(255, 255, 255, 0.31),
      rgba(255, 255, 255, 0.11));
  border-radius: 100%;
}

.circle_bg-bal_small {
  height: 100px;
  width: 100px;
  animation: bubble-anim 2s ease-out infinite;
}

.circle_bg-bal_middle {
  height: 150px;
  width: 150px;
  animation: bubble-anim 8s ease-out infinite;
}

.circle_bg-bal_versmall {
  height: 40px;
  width: 40px;
}

.circle_bg-bal_big {
  height: 200px;
  width: 200px;
  animation: bubble-anim 4s ease-out infinite;
}

@keyframes bubble-anim {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scaleY(0.95) scaleX(1.05);
  }

  48% {
    transform: scaleY(1.1) scaleX(0.9);
  }

  68% {
    transform: scaleY(0.98) scaleX(1.02);
  }

  80% {
    transform: scaleY(1.02) scaleX(0.98);
  }

  97%,
  100% {
    transform: scale(1);
  }
}

.gradient-bg-figure {
  position: absolute;
  width: 550px;
  height: 200px;
  border-radius: 200px;
  background: rgba(255, 255, 255, 0.07);
  transform: rotate(35deg);
  box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.03);
}

/*-------------Footer---------------------------------------*/
.footer-inner {
  padding: 50px 0 50px;
  overflow: hidden;
}

.main-footer {
  color: #3B4249;
  background: white;
  overflow: hidden;
  z-index: 6;
}

.sub-footer {
  padding: 18px 0;
  color: #3B4249;
  background: white;
}

.sub-footer .lang-wrap {
  color: #3B4249;
  top: 0;
  background: white;
  border-radius: 4px;
  margin-right: 0;
}

.sub-footer .lang-wrap:hover .lang-tooltip {
  top: -116px;
}

.sub-footer .copyright {
  color: #3B4249 !important;
  text-align: left;
  font-weight: 500;
  position: relative;
  top: 16px;
  font-size: 12px;
}

.subfooter-nav {
  float: right;
  margin-right: 30px;
  position: relative;
  top: 12px;
}

.subfooter-nav:before {
  content: "";
  position: absolute;
  right: -22px;
  top: 50%;
  height: 10px;
  margin-top: -5px;
  width: 1px;
  color: #3B4249;
}

.subfooter-nav li {
  margin-left: 15px;
}

.subfooter-nav li a {
  color: #3B4249;
  font-weight: 400;
  font-size: 12px;
}

.footer-social {
  margin-top: 24px;
}

.footer-social span {
  margin-right: 15px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.footer-social ul,
.footer-social li,
.footer-social li a,
.footer-social span,
.footer-social,
.subfooter-nav li,
.sub-footer .copyright {
  float: left;
}

.footer-social li {
  margin-right: 16px;
}

.footer-social li a {
  font-size: 13px;
}

.footer-bg {
  position: absolute;
  left: 0;
  width: 500px;
  height: 400px;
  bottom: -50px;
  z-index: 1;
  opacity: 0.6;
  /* background: url(../images/map-bg.png) center no-repeat; */
}

.footer-bg-pin {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 100%;
}

.footer-bg-pin:first-child {
  left: 120px;
  top: 200px;
}

.footer-bg-pin:nth-child(2) {
  left: 230px;
  top: 200px;
}

.footer-bg-pin:nth-child(3) {
  left: 136px;
  top: 300px;
}

.footer-bg-pin:nth-child(4) {
  right: 190px;
  top: 220px;
}

.footer-bg-pin:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 100%;
  z-index: -1;
  opacity: 0.4;
}

@keyframes pulse {
  100% {
    transform: scale(22.1);
    opacity: 0;
  }
}

.footer-bg-pin-vis:before {
  animation: pulse 2s infinite ease-in-out;
}

.footer-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 40px 0 30px;
}

.subscribe-header {
  float: left;
  position: relative;
  top: 6px;
  text-align: left;
}

.subscribe-header h3 i {
  padding-left: 20px;
  font-weight: 100;
}

.subscribe-header h3 {
  font-weight: 600;
  position: relative;
  font-size: 16px;
  color: #fff;
  padding-bottom: 6px;
}

.subscribe-header p {
  color: rgba(255, 255, 255, 0.6);
}

#subscribe .enteremail {
  background: #fff;
  height: 50px;
  line-height: 50px;
  border: none;
  padding: 0 120px 0 25px;
  box-sizing: border-box;
  z-index: 1;
  overflow: hidden;
  border-radius: 30px;
}

#subscribe .enteremail::-webkit-input-placeholder {
  color: #999;
  font-weight: 500;
  font-size: 13px;
}

#subscribe {
  float: left;
  width: 100%;
  position: relative;
}

#subscribe-button {
  position: absolute;
  right: 0;
  width: 120px;
  height: 50px;
  line-height: 50px;
  top: 0;
  cursor: pointer;
  border: none;
  z-index: 2;
  font-size: 22px;
  z-index: 10;
  background: none;
}

#subscribe-button:hover i:before {
  content: "\f2b6";
}

#subscribe-button:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 20px;
  background: #fff;
  left: 0;
  top: 50%;
  margin-top: -10px;
}

.subscribe-message {
  color: #fff;
  float: left;
  margin-top: 10px;
  text-align: left;
  font-weight: 500;
  line-height: 19px;
}

.subscribe-message i {
  padding-right: 6px;
}

.subscribe-message.valid i {
  color: #5ecfb1;
}

.subscribe-message.error i {
  color: #f00;
}

.footer-widget #subscribe-button i {
  padding-right: 6px;
}

.footer-logo {
  float: left;
  margin-bottom: 40px;
}

.footer-logo img {
  width: auto;
  height: 30px;
}

.footer-wave,
.section-wave {
  position: absolute;
  /* bottom: -160px; */
  width: 100%;
  left: 0;
  z-index: 2;
  height: 400px;
  opacity: 0.06;
}

.footer-widget h3 {
  font-size: 16px;
  float: left;
  text-align: left;
  width: 100%;
  margin-bottom: 26px;
  color: #3B4249;
  font-weight: 600;
  border-bottom: 1px solid rgba(255, 255, 255, 0.11);
  padding-bottom: 25px;
  position: relative;
}

.footer-widget h3:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 1px;
}

.footer-list li {
  display: block;
  text-align: left;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
}

.footer-list li a {
  color: #3B4249;
}

.footer-list li:before {
  font-family: Font Awesome\5 Pro;
  content: "\f0da";
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 11px;
  transition: all 200ms linear;
  color: #4db7fe;
}

.footer-list li:hover:before {
  left: 4px;
}

.footer-link {
  float: left;
  color: #3B4249;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  margin-top: 18px;
}

.footer-link.twitter-link {
  margin-top: 24px;
}

.footer-link i {
  margin-left: 14px;
}

.footer-widget .down-btn {
  margin: 15px 0 0 0;
}

.no-border {
  border: none !important;
}

.footer-link {
  float: left;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  margin-top: 18px;
}

.footer-link i {
  margin-left: 14px;
}

.footer-widget .footer-contacts-widget p {
  color: #fff;
  text-align: left;
  line-height: 24px;
  margin-bottom: 10px;
}

.footer-contacts {
  margin-top: 15px;
}

.footer-contacts li {
  float: left;
  text-align: left;
  min-width: 250px;
  padding: 11px 15px;
  font-size: 13px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.11);
  border-radius: 30px;
  margin-bottom: 12px;
}

.footer-contacts li a,
.footer-contacts li span {
  color: #3B4249;
}

.footer-contacts li a {
  color: #3B4249;
  padding-left: 4px;
}

.footer-contacts li i {
  padding-right: 7px;
}

.footer-widget-posts li {
  margin-bottom: 30px;
}

.footer-widget-posts li:last-child {
  margin-bottom: 0;
}

.contact-btn {
  float: left;
  color: #fff;
  padding: 15px 35px;
  font-size: 12px;
  margin-top: 25px;
  font-weight: 600;
  border-radius: 30px;
  position: relative;
}

.contact-btn i {
  margin-left: 10px;
}

.footer-widget-posts .widget-posts-img {
  float: left;
  width: 25%;
}

.footer-widget-posts .widget-posts-img img {
  border-radius: 4px;
}

.footer-widget-posts .widget-posts-descr {
  float: left;
  width: 75%;
  padding-left: 12px;
  text-align: left;
  position: relative;
}

.footer-widget-posts .widget-posts-descr:before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 12px;
  right: 0;
  height: 1px;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.21);
}

.footer-widget-posts .widget-posts-descr a {
  font-size: 13px;
  color: #3B4249;
  font-weight: 600;
  padding-bottom: 20px;
}

.footer-widget-posts .widget-posts-descr a:hover {
  color: #D53c2c;
}

.footer-widget-posts .widget-posts-date {
  float: left;
  width: 100%;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.51);
  font-weight: 600;
  font-size: 11px;
}

.footer-widget-posts .widget-posts-date i {
  padding-right: 10px;
}

/*------ to-top------------------------------------------------*/
.to-top {
  position: fixed;
  bottom: 60px;
  right: 50px;
  width: 40px;
  height: 40px;
  color: #fff;
  background: #425998;
  line-height: 40px;
  font-size: 17px;
  z-index: 116;
  cursor: pointer;
  display: none;
  border-radius: 3px;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(0, 0, 0);
}

.to-top:hover {
  background: #253966;
}

.to-top.to-top_footer {
  position: absolute;
}

/*-------------box item---------------------------------------*/
.box-item {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.box-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  background: rgba(51, 51, 51, 0.41);
  transition: all 300ms linear;
}

.box-item a.gal-link {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  color: #fff;
  font-size: 8px;
  z-index: 10;
  top: 40%;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.4);
  transition: all 300ms linear;
}

.list-single-gallery .box-item {
  border-radius: 6px;
}

.box-item:hover a.gal-link {
  top: 50%;
  opacity: 1;
}

.box-item:hover:before {
  opacity: 1;
}

/*-------------map ,  infobox and markers ---------------------------------------*/
.map-container {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.fw-map {
  height: 500px;
}

.fw-map.big_map {
  height: 550px;
}

.fw-map2 #singleMap,
.fw-map2 {
  height: 500px;
}

.hidden-map-container {
  display: none;
  background: #fff;
  overflow: hidden;
}

#singleMap,
.fw-map-small-map {
  height: 420px;
}

#singleMap {
  height: 300px;
}

.map-container.column-map.right-pos-map {
  right: 0;
}

.map-container.column-map.left-pos-map {
  left: 0;
}

.map-container #map-main {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 990;
}

.map-container.column-map {
  width: calc(100% - 850px);
  position: absolute;
  overflow: hidden;
}

.map-container.column-map.no-fix-scroll-map {
  position: fixed;
  right: 0;
}

.map-close {
  position: absolute;
  left: 365px;
  top: 10px;
  z-index: 1100;
  cursor: pointer;
  width: 43px;
  height: 43px;
  line-height: 43px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  display: none;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.marker-count {
  width: 20px;
  height: 20px;
  right: 0;
  top: 0;
  border-radius: 100%;
  background: #4e65a3;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  font-size: 10px;
  color: #fff;
  position: absolute;
  z-index: 100;
}

.map-modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  display: none;
  overflow: auto;
  -webkit-transform: translate3d(0, 0, 0);
}

.map-modal-wrap-overlay {
  position: fixed;
  top: 0;
  left: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.41);
  -webkit-transform: translate3d(0, 0, 0);
}

.map-modal-item {
  position: relative;
  max-width: 650px;
  margin: 0 auto;
  top: 25%;
  z-index: 2;
}

.map-modal-container {
  padding: 0;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.map-modal-container h3 {
  float: left;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  margin: 15px 0;
  color: #666;
  padding-left: 20px;
}

.map-modal-container h3 span {
  color: #566985;
}

.map-modal #singleMap {
  margin-bottom: 0;
}

.map-modal-close {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 4px;
  color: #ccc;
  background: #fff;
  z-index: 10;
  cursor: pointer;
  border-left: 1px solid #eee;
}

.cluster img {
  display: none;
}

.cluster-visible {
  text-align: center;
  font-size: 11px !important;
  color: #fff !important;
  font-weight: 500;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
  background: #475897;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.5);
}

.cluster-visible:before {
  border: 17px solid #475897;
  opacity: 0.5;
  content: "";
  position: absolute;
  border-radius: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  box-sizing: border-box;
  animation: cluster-animation 2.5s infinite;
}

@keyframes cluster-animation {
  100% {
    transform: scale(2.8) rotate(90deg);
    opacity: 0;
  }
}

.mapzoom-in,
.mapzoom-out {
  position: absolute;
  z-index: 100;
  top: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  right: 12px;
  margin-top: -84px;
  -webkit-transform: translate3d(0, 0, 0);
  background: #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.25);
}

.mapzoom-out {
  margin-top: -43px;
  border-radius: 0 0 4px 4px;
}

.mapzoom-in {
  border-radius: 4px 4px 0 0;
}

.mapzoom-in:before,
.mapzoom-out:before {
  font-family: Font Awesome\5 Pro;
  content: "\f068";
  font-size: 10px;
  font-weight: 600;
}

.mapzoom-in:before {
  content: "\f067";
}

.mapnavigation {
  position: absolute;
  bottom: 50px;
  right: 64px;
  z-index: 1030;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.25);
}

.mapnavigation li {
  float: left;
  position: relative;
  margin-left: 1px;
}

.mapnavigation li:first-child a {
  border-radius: 4px 0 0 4px;
}

.mapnavbtn,
.location-btn {
  width: 40px;
  height: 40px;
  float: left;
  font-size: 11px;
  background: #fff;
  cursor: pointer;
}

.location-btn {
  position: absolute !important;
}

.mapnavbtn span,
.location-btn span {
  line-height: 40px;
}

.scrollContorl.mapnavbtn,
.location-btn {
  float: none;
  position: absolute;
  bottom: 95px;
  right: 19px;
  z-index: 1030;
  border-radius: 4px;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.25);
}

.location-btn {
  background: #425998;
  right: 19px;
  bottom: 50px;
}

#markerLayer img {
  width: 60px !important;
  height: 60px !important;
  border-radius: 100%;
  border: 4px solid #fff !important;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.25) !important;
}

.label {
  width: 15px;
  height: 15px;
  background: black;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 7px rgba(77, 183, 254, 0.2);
  animation: marker-animation2 2.5s infinite;
  position: relative;
}

@keyframes marker-animation2 {
  50% {
    box-shadow: 0px 0px 0px 17px rgba(77, 183, 254, 0.4);
  }
}

.map-popup-wrap {
  display: block;
  width: 300px !important;
  position: relative;
  z-index: 1 !important;
}

.map-popup {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.map-popup img {
  width: 100%;
  height: auto;
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.listing-img-content:hover img {
  transform: scale(1.15);
}

.listing-img-content {
  border-radius: 10px;
}

.listing-content {
  z-index: 21;
  position: relative;
  float: left;
  width: 100%;
  top: -10px;
  transition: all 0.2s ease-in-out;
}

.listing-content-item {
  border-radius: 10px;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.25);
  background: #fff;
}

.infoBox-close {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  top: 0;
  right: 0;
  color: #999;
  border-radius: 0 0 0 8px;
  z-index: 20;
  background: #fff;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.listing-title {
  padding: 15px 20px;
}

.listing-title h4 {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #566985;
}

.listing-title h4 a {
  color: #566985;
}

.listing-img-content {
  overflow: hidden;
}

.listing-img-content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #292929;
  opacity: 0.3;
}

.map-popup-footer {
  float: left;
  padding: 0 20px;
  width: 100%;
  height: 46px;
  border-top: 1px solid #eee;
  background: #fff;
  border-radius: 0 0 10px 10px;
}

.map-popup-footer .main-link {
  float: left;
  color: #7d93b2;
  height: 30px;
  line-height: 30px;
  border-radius: 2px;
  position: relative;
  top: 8px;
  font-size: 12px;
  font-weight: 600;
}

.map-popup-footer .main-link i {
  padding-left: 10px;
}

.map-popup-location-info {
  float: left;
  width: 100%;
  color: #7d93b2;
  text-align: left;
  padding-top: 6px;
}

.map-popup-location-info i {
  padding-right: 10px;
}

.infowindow_wishlist-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 46px;
  line-height: 46px;
  border-left: 1px solid #eee;
  font-size: 16px;
  z-index: 10;
}

.map-marker-container {
  position: absolute;
  margin-top: 10px;
  transform: translate3d(-50%, -100%, 0);
}

.marker-container {
  position: relative;
  width: 60px;
  height: 60px;
  z-index: 1;
  cursor: pointer;
  top: -5px;
  perspective: 500px;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.marker-container:hover {
  transform: translate3d(0, 8px, 0);
}

.marker-card {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.marker-card:before {
  width: 0;
  content: "";
  height: 0;
  border-style: solid;
  border-width: 10px 6px 0;
  top: 58px;
  left: 50%;
  border-color: #fff transparent transparent;
  transform: translateX(-50%);
  position: absolute;
  z-index: 3;
}

.marker-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  z-index: 100;
  overflow: hidden;
  border: 4px solid #fff;
  z-index: 2;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.45) !important;
}

.marker-holder img {
  width: auto;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -26px;
}

.map-popup-location-category {
  position: absolute;
  top: -21px;
  right: 20px;
  width: 42px;
  height: 42px;
  z-index: 30;
  border-radius: 50px;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 1);
}

.map-popup-location-category:after {
  font-family: Font Awesome\5 Pro;
  position: absolute;
  width: 100%;
  top: 13px;
  left: 0;
  font-size: 14px;
  color: #fff;
  font-weight: 100;
}

.map-popup-location-category.hotels-cat:after {
  content: "\f236";
}

.map-popup-location-category.cafe-cat:after {
  content: "\f805";
}

.map-popup-location-category.gym-cat:after {
  content: "\f44b";
}

.map-popup-location-category.event-cat:after {
  content: "\f561";
}

.map-popup-location-category.shop-cat:after {
  content: "\f218";
}

.card-popup-raining {
  position: absolute;
  z-index: 10;
  left: 20px;
  bottom: 26px;
}

.card-popup-raining i {
  float: left;
  color: #facc39;
  margin-right: 3px;
  font-size: 11px;
}

.map-popup-reviews-count {
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  top: -2px;
}

.infobox-status {
  position: absolute;
  top: 20px;
  left: 20px;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.3);
  z-index: 22;
  color: #fff;
  padding: 4px 20px;
  border-radius: 10px;
}

.scrollContorl.enabledsroll i:before {
  content: "\f3c1";
}

/*------ listing------------------------------------------------*/
.fw-sear ch-form_wrap {
  background: #fff;
  z-index: 20;
  height: 70px;
  border-bottom: 1px solid #eee;
}

.col-list-wrap {
  position: relative;
  width: 850px;
  float: left;
  background: #f5f6fa;
  min-height: 100vh;
  z-index: 50;
  left: 0;
}

.col-list-wrap.anim_clw {
  padding-top: 90px;
}

.col-list-wrap.anim_clw.hidsb_act,
.list-main-wrap-header.anim_clw.hidsb_act {
  left: 380px !important;
}

.list-main-wrap-header.anim_clw,
.col-list-wrap.anim_clw {
  transition: all 0.2s ease-in-out;
}

.show-hidden-sb {
  float: right;
  background: #425998;
  color: #fff;
  position: relative;
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
  padding: 0 20px;
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  top: 2px;
}

.show-hidden-sb i {
  margin-right: 10px;
}

.hidden-search-column {
  position: fixed;
  left: 0;
  width: 380px;
  background: #fff;
  z-index: 1;
  top: 80px;
  bottom: 0;
  border-right: 1px solid #eee;
}

.scrl-content::-webkit-scrollbar {
  width: 0px;
}

.scrl-content {
  position: absolute;
  left: 0;
  top: 100px;
  bottom: 0;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrl-content.fs-viscon {
  float: left;
  position: relative;
  width: 100%;
  top: 0;
}

.hidden-search-column-container,
.hidden-search-column {
  overflow: hidden;
}

.hc-item {
  margin-bottom: 120px;
}

.filter-sidebar-header {
  margin-bottom: 20px;
  padding: 25px 30px;
}

.filter-sidebar {
  padding: 0 30px;
}

.filter-sidebar-header {
  z-index: 2;
}

.filter-sidebar-header .tabs-menu li {
  width: 50%;
}

.filter-sidebar-header .tabs-menu li a {
  color: #566985;
  font-weight: 400;
  text-align: left;
}

.list-main-wrap-header {
  background: #fff;
  padding: 16px 0;
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.09);
}

.list-main-wrap-header.no-vis-shadow {
  padding: 12px 20px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  z-index: 150;
}

.list-main-wrap-header.no-bg-header {
  border-radius: 0 0 6px 6px;
  margin-bottom: 30px;
  border-top: none;
}

.list-main-wrap-header.no-vis-shadow .list-main-wrap-title h2 {
  top: 10px;
}

.list-main-wrap-header.anim_clw {
  position: fixed;
  top: 80px;
  left: 0;
  width: 850px;
  z-index: 20;
}

.grid-opt {
  margin-left: 12px;
  position: relative;
  top: 3px;
}

.grid-opt li {
  float: left;
  position: relative;
}

.grid-opt li.grid-opt_act {
  cursor: pointer;
  font-size: 18px;
  width: 36px;
  height: 36px;
  line-height: 36px;
}

.grid-opt li.grid-opt_act span {
  line-height: 12px;
  color: #ccc;
}

.clbtg {
  position: absolute;
  right: -40px;
  bottom: 0;
  height: 40px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  background: #4e65a3;
  color: #fff;
  display: none;
  z-index: 20;
  font-size: 12px;
  border-radius: 0 4px 4px 0;
}

.clbtg:hover {
  color: #fff;
}

.clbtg:hover i:before {
  content: "\f0d8";
  font-weight: 900;
}

.listsearch-input-wrap {
  background: #fff;
  border-bottom: 1px solid #eee;
  z-index: 10;
}

.listsearch-input-wrap.inline-lsiw {
  margin-bottom: 20px;
  border: none;
  background: none;
  z-index: 50;
}

.listsearch-input-wrap.inline-lsiw .listsearch-input-wrap_contrl {
  padding-top: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #e5e7f2;
}

.listsearch-input-wrap.inline-lsiw .tabs-container {
  padding: 35px 30px 0;
  border-radius: 0 0 6px 6px;
  background: #fff;
  border: 1px solid #e5e7f2;
  border-top: none;
}

.listsearch-input-wrap_contrl {
  padding-top: 25px;
  margin-bottom: 35px;
  background: #f5f6fa;
  overflow: hidden;
  border-bottom: 1px solid #eee;
}

.inline-lsiw .listsearch-input-wrap_contrl {
  overflow: inherit;
}

.listsearch-input-wrap_contrl li {
  float: left;
  width: 50%;
}

.inline-lsiw .listsearch-input-wrap_contrl li {
  width: calc(50% - 10px);
  margin-bottom: 10px;
}

.inline-lsiw .listsearch-input-wrap_contrl li:last-child {
  float: right;
}

.listsearch-input-wrap_contrl li a {
  display: block;
  position: relative;
  padding: 18px 0;
  border: 1px solid transparent;
  border-bottom: none;
  font-size: 13px;
  color: #888da0;
  background: rgba(255, 255, 255, 0.1);
  font-weight: 700;
  border-radius: 6px 6px 0 0;
}

.inline-lsiw .listsearch-input-wrap_contrl li a {
  padding: 16px 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 9px 16px rgba(58, 87, 135, 0.15);
  border: 1px solid #e5e7f2;
  font-weight: 400;
}

.listsearch-input-wrap_contrl li.current a {
  background: #fff;
  border-color: #eee;
  color: #566985;
  box-shadow: 0 0px 30px 0px rgba(0, 0, 0, 0.04);
}

.inline-lsiw .listsearch-input-wrap_contrl li.current a {
  color: #fff;
}

.listsearch-input-wrap .tab-content {
  padding-bottom: 25px;
}

.listsearch-input-wrap .tab-content.first-tab {
  padding-bottom: 55px;
}

.listsearch-input-wrap-header {
  text-align: left;
  font-size: 14px;
  color: #566985;
  font-weight: 500;
  margin-bottom: 20px;
}

.listsearch-input-wrap-header i,
.listsearch-input-wrap_contrl li a i {
  margin-right: 10px;
}

.list-main-wrap-title {
  float: left;
  position: relative;
}

.list-main-wrap-opt {
  float: right;
  position: relative;
}

.grid-opt,
.price-opt {
  float: left;
}

.price-opt-title {
  float: left;
  padding-right: 14px;
  position: relative;
  top: 14px;
  font-size: 12px;
  color: #999;
  font-weight: 900;
}

.price-opt .listsearch-input-item {
  width: 180px;
  float: left;
  margin-bottom: 0;
}

.price-opt .listsearch-input-item .nice-select {
  height: 41px;
  line-height: 41px;
}

.fw-col-list-wrap .list-main-wrap-opt .list-main-wrap-title {
  padding: 0 0 10px 0;
  margin-top: 0;
  margin-bottom: 10px;
}

.list-main-wrap-title h2 {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  float: left;
  color: #566985;
  position: relative;
  top: 12px;
}

.filter-tags-btn {
  text-align: left;
  height: 41px;
  line-height: 41px;
  border-radius: 4px;
}

.filter-tags-btn i {
  margin-right: 12px;
}

.listsearch-input-item {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.listsearch-input-item span.iconn-dec,
.main-search-input-item span.iconn-dec {
  position: absolute;
  left: 20px;
  bottom: 15px;
}

.main-search-input-item span.iconn-dec {
  bottom: 16px;
}

.listsearch-input-item .header-search-button {
  width: 100%;
  height: 41px;
  line-height: 41px;
  font-weight: 400;
  font-size: 13px;
  border-radius: 30px;
  position: relative;
}

.listsearch-input-item .header-search-button span {
  position: relative;
  padding-left: 12px;
}

.listsearch-input-item .header-search-button span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 10px;
  margin-top: -5px;
  width: 1px;
  background: rgba(255, 255, 255, 0.3);
}

.more-filter-option-wrap {
  position: absolute;
  left: 0;
  bottom: -55px;
}

.more-filter-option-btn {
  float: left;
  height: 40px;
  line-height: 40px;
  color: #566985;
  border-radius: 4px 4px 0 0;
  background: #fff;
  border: 1px solid #e5e7f2;
  border-bottom: none;
  padding: 0 30px;
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.more-filter-option-btn i,
.clear-filter-btn i {
  margin-right: 10px;
}

.hidden-listing-filter {
  margin-top: 12px;
  display: none;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.mar-btoom {
  margin-bottom: 12px;
}

.clear-singleinput {
  position: absolute;
  right: 14px;
  bottom: 14px;
  z-index: 3;
  cursor: pointer;
}

.clear-filter-btn {
  float: left;
  color: #566985;
  font-size: 11px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.filter-tags li {
  float: left;
  margin: 0 10px 10px 0;
}

.filter-sidebar .filter-tags li {
  width: 50%;
  margin: 0 0 10px 0;
}

.toggle-filter-btn {
  border: 1px solid #e5e7f2;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  height: 48px;
  line-height: 48px;
  float: left;
  width: 100%;
  background: #f7f9fb;
  color: #566985;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
}

.toggle-filter-btn.tsb_act,
.more-filter-option-btn.active-hidden-opt-btn,
.inline-lsiw .more-filter-option-btn {
  background: #425998;
  color: #fff;
}

.toggle-filter-btn:after {
  content: "";
  position: absolute;
  right: -30px;
  top: -20px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background: #fff;
  opacity: 0;
}

.more-filter-option-btn.active-hidden-opt-btn {
  border-color: transparent;
}

.more-filter-option-btn.active-hidden-opt-btn i:before {
  content: "\f00d";
}

.toggle-filter-btn.tsb_act:before {
  font-family: Font Awesome\5 Pro;
  content: "\f00c";
  position: absolute;
  bottom: -1px;
  font-size: 12px;
  right: 12px;
  color: #fff;
}

.toggle-filter-btn.tsb_act:after {
  opacity: 0.2;
}

.toggle-filter-btn i {
  margin-right: 5px;
}

.price-rage-wrap {
  padding-left: 80px;
}

.price-rage-item {
  top: -6px;
}

.price-rage-wrap-title {
  position: absolute;
  left: 0;
  top: 12px;
  text-align: left;
  font-size: 13px;
  color: #888da0;
  font-weight: 400;
}

.price-rage-wrap-title i {
  margin-right: 4px;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.category-carousel-wrap,
.single-carousel-wrap {
  height: 190px;
  overflow: hidden;
  background: #fff;
}

.category-carousel .swiper-slide img,
.single-carousel-wrap .swiper-slide img {
  position: relative;
  z-index: 1;
}

.category-carousel .swiper-slide,
.single-carousel-wrap .swiper-slide,
.category-carousel .swiper-slide img,
.single-carousel-wrap .swiper-slide img {
  width: auto !important;
  height: 190px !important;
}

.category-carousel .swiper-slide,
.single-carousel-wrap .swiper-slide {
  display: inherit !important;
}

.category-carousel-item,
.single-carousel-wrap .swiper-slide .box-item {
  overflow: hidden;
  border-radius: 10px;
}

.sc-btn,
.ss-slider-cont {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin-top: -18px;
  z-index: 100;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.4);
}

.sc-btn.sc-next,
.ss-slider-cont-next {
  right: 10px;
}

.sc-btn.sc-prev,
.ss-slider-cont-prev {
  left: 10px;
}

.filter-sidebar .category-carousel-item img {
  width: 100%;
  height: auto;
}

.filter-sidebar .category-carousel-item {
  margin-bottom: 10px;
}

.checket-cat:after {
  font-family: Font Awesome\5 Pro;
  content: "\f00c";
  position: absolute;
  color: #fff;
  top: 50%;
  font-size: 13px;
  right: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin: -38px -18px 0 0;
  border-radius: 100%;
  z-index: 20;
  color: #fff;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.4);
}

.category-carousel-item:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 5%;
  background: linear-gradient(to bottom,
      rgba(6, 27, 65, 0) 0%,
      rgba(6, 27, 65, 0.95) 100%);
  z-index: 2;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
}

.category-carousel-item-icon {
  position: absolute;
  left: 19px;
  bottom: 19px;
  color: #fff;
  font-size: 17px;
  z-index: 20;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  font-weight: 100;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.25);
}

.category-carousel-item-container {
  position: absolute;
  bottom: 12px;
  right: 12px;
  left: 12px;
  background: #fff;
  padding: 8px 0;
  z-index: 3;
  border-radius: 40px;
}

.category-carousel-item-title,
.category-carousel-item-counter {
  color: #566985;
  z-index: 20;
  font-size: 13px;
  font-weight: 600;
}

.category-carousel-item-counter {
  padding-top: 3px;
  font-size: 12px;
  font-weight: 400;
  color: #7d93b2;
}

.catcar-scrollbar {
  height: 5px;
  position: relative;
  z-index: 20;
  margin-top: 25px;
}

.hs_init {
  position: absolute;
  left: 0;
  right: 90px;
  height: 5px;
  top: 0;
}

.catcar-scrollbar .swiper-scrollbar-drag {
  background: #4e65a3;
}

.hs_init:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background: #e5e7f2;
  top: 50%;
  margin-top: -1px;
}

.cc-contorl {
  position: absolute;
  right: 0;
  top: -10px;
  width: 80px;
  height: 26px;
  line-height: 26px;
}

.cc-contrl-item {
  float: left;
  width: 40px;
  cursor: pointer;
  color: #9ea6ba;
  font-size: 14px;
}

.cc-contrl-item:hover {
  background: #f7f9fb;
}

/*------------- listing card ---------------------------------------*/
.listing-item-container {
  padding: 30px 0 50px;
}

.listing-item-container.nocolumn-lic {
  padding: 30px 0 0;
}

.listing-item {
  float: left;
  width: 50%;
  padding: 0 8px 0 0;
  margin-bottom: 12px;
  position: relative;
}

.gallery-item .listing-item {
  height: auto !important;
}

.listing-item:nth-child(even) {
  padding: 0 0 0 8px;
}

.three-columns-grid .listing-item {
  width: 33.3%;
  padding: 0 8px !important;
}

.listing-item.has_one_column,
.slick-slide-item .listing-item {
  width: 100%;
}

.listing-item.has_one_column {
  padding: 0 !important;
}

.listing-item.has_one_column .geodir-category-listing {
  padding: 0 !important;
}

.listing-item.has_one_column .geodir-category-listing .geodir-category-img:before {
  display: none;
}

.listing-item.has_one_column .geodir-category-img {
  width: 44%;
  border-radius: 10px;
  top: 0;
  overflow: hidden;
}

.listing-item.has_one_column .hotel-card .geodir-category-img {
  width: 100%;
  border-radius: 10px;
  top: 0;
}

.three-columns-grid .listing-item.has_one_column .geodir-category-listing {
  padding-left: 0 !important;
}

.three-columns-grid .listing-item.has_one_column .geodir-category-img {
  top: 0px;
}

.geodir-category-listing {
  border-radius: 10px;
  background: #fff;
  border: 1px solid #eee;
}

.geodir-category-listing:after {
  content: "";
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  z-index: -1;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 10px 14px 0 rgba(37, 44, 65, 0.12);
  transition: all 0.3s ease;
}

.geodir-category-listing:hover:after {
  box-shadow: 0 16px 14px 0 rgba(37, 44, 65, 0.12);
}

.geodir-category-content {
  z-index: 2;
}

.geodir-category-img {
  float: left;
  width: 100%;
  position: relative;
  z-index: 11;
  border-radius: 10px 10px 0 0;
}


.listing_carditem .geodir-category-img:before {
  display: none;
}

.geodir-category-img-wrap {
  overflow: hidden;
  border-radius: 10px;
}

.listing_carditem .geodir-category-img-wrap {
  border-radius: 10px;
}

.listing_carditem_footer {
  margin-top: 10px;
  padding: 14px 0 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.geodir-category-img-wrap:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 30%;
  background: linear-gradient(to bottom,
      rgba(6, 27, 65, 0) 0%,
      rgba(6, 27, 65, 0.95) 100%);
  z-index: 2;
}

.listing_carditem_footer .listing-item-category-wrap span,
.listing_carditem .geodir-category-location a,
.listing_carditem_footer .geodir-category_price .price-level-item strong,
.listing_carditem_footer .post-author a span {
  color: #fff;
}

.listing_carditem .listing-rating-count-wrap {
  float: right;
}

.geodir-category-opt_title {
  float: left;
  position: relative;
  top: -5px;
}

.listing_carditem_footer .geodir-category_price {
  float: left;
  margin: 0 0 0 26px;
  color: #fff;
  top: 11px;
}

.listing_carditem_footer .post-author {
  margin-left: 25px;
  margin-top: 0;
  top: -2px;
  position: relative;
}

.geodir-category-img .listing-counter {
  top: 20px;
  left: 20px;
}

.geodir-category-img .listing-counter strong {
  padding-left: 4px;
}

.geodir-category-img-wrap img,
.gallery-item img {
  width: 100%;
  height: auto;
}

.listing-item-grid .bg,
.geodir-category-img-wrap img,
.gallery-item img {
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}

.geodir-category-listing:hover .geodir-category-img-wrap img,
.gallery-item:hover img,
.listing-item-grid:hover .bg {
  transform: scale(1.12);
}



.geodir-js-favorite_btn {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 20;
  cursor: pointer;
  color: #fff;
}


.geodir-js-favorite_btn i {
  float: left;
  width: 36px;
  height: 36px;
  line-height: 39px;
  border-radius: 100%;
  background: #af2322 !important;
  position: relative;
  z-index: 2;
}

.geodir-js-favorite_btn span {
  border-radius: 36px;
  background: #af2322;
  float: left;
  left: -36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  padding: 0 18px 0 48px;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  margin-top: -2px;
  visibility: hidden;
}

.geodir-js-favorite_btn:hover span {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}






.geodir-js-favorite_btn1 {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 20;
  cursor: pointer;
  color: #fff;
}


.geodir-js-favorite_btn1 i {
  float: left;
  width: 36px;
  height: 36px;
  line-height: 39px;
  border-radius: 100%;
  color: #fff;
  background: #af2322;
  position: relative;
  z-index: 2;
}

.geodir-js-favorite_btn1 span {
  border-radius: 36px;
  background: #af2322;
  float: left;
  left: -36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  padding: 0 18px 0 48px;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  margin-top: -2px;
  visibility: hidden;
}

.geodir-js-favorite_btn1:hover span {
  opacity: 1;
  visibility: visible;

  margin-top: 0;
}

.geodir-js-favorite_btn1 i {
  font-weight: 900;

}






.geodir-js-favorite_btn3 {
  /* position: absolute;
  left: 20px;
  top: 20px; */
  padding-top: 7px;
  z-index: 20;
  cursor: pointer;
  color: #fff;
}

.geodir-js-favorite_btn3 i {
  float: left;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 100%;
  background: #8EC639;
  position: relative;
  z-index: 2;
}

.geodir-js-favorite_btn3 span {
  border-radius: 36px;
  background: #8EC639;
  float: left;
  left: -36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  padding: 0 18px 0 48px;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  margin-top: -2px;
  visibility: hidden;
}

.geodir-js-favorite_btn3:hover span {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.geodir-js-favorite_btn3:hover i {
  font-weight: 900;
}

.listing-avatar {
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 14;
}

.listing-avatar img {
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 1);
  border-radius: 100%;
  position: relative;
  z-index: 2;
}

.avatar-tooltip {
  position: absolute;
  top: 0;
  margin-top: 4px;
  opacity: 0;
  right: 0;
  padding-right: 60px;
  min-width: 200px;
  visibility: hidden;
  height: 56px;
  line-height: 56px;
  border-radius: 56px;
  z-index: 1;
  background: #fff;
  color: #878c9f;
  font-size: 11px;
  font-weight: 600;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.15);
  transition: all 0.2s ease-in-out;
}

.listing-avatar:hover .avatar-tooltip {
  margin-top: -2px;
  opacity: 1;
  visibility: visible;
}

.geodir-category-content p {
  text-align: left;
  font-size: 12px;
  color: #999;
  font-weight: 500;
  line-height: 24px;
}

.geodir-category-content h3,
.cart-details_text .geodir-category-content-title-item h3 {
  float: left;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Nunito", sans-serif;
}

.geodir-category-content h3 a {
  color: #566985;
}

.verified-badge {
  display: inline-table;
  margin-left: 12px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 100%;
  color: #fff;
  font-size: 10px;
  text-align: center;
  font-weight: 900;
  position: relative;
  top: -2px;
}

.geodir-category-content-tags {
  margin-top: 10px;
}

.geodir_status_date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 20;
  padding: 6px 10px;
  color: #fff;
  z-index: 20;
  font-size: 11px;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
}

.geodir_status_date i {
  margin-right: 10px;
}

.geodir_status_date.gsd_open i {
  color: #fff;
}

.listing-item.has_one_column .geodir-category-content {
  width: 56%;
  padding-top: 15px;
}

.listing-item.has_one_column .geodir-category-footer {
  margin: 0;
  padding: 6px 20px;
}

.listing-item.has_one_column .geodir-category-content-title {
  padding: 0 20px 10px;
}

.listing-item.has_one_column .facilities-list {
  margin: 3px 0 10px;
}

.listing-item.has_one_column .geodir-category-opt {
  bottom: 3px;
}

.button-text {
  font-size: 12px;
  font-family: Quicksand;
}

.listing-item.has_one_column .listing-avatar {
  bottom: 20px;
  right: 20px;
}

.listing-price {
  position: absolute;
  bottom: 100px;
  left: 12px;
  z-index: 14;
}

.listing-item.has_one_column .listing-avatar img {
  width: 30px;
  height: 30px;
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
}

.listing-item.has_one_column .avatar-tooltip {
  padding-right: 50px;
  min-width: 180px;
  right: -3px;
  top: -1px;
  height: 36px;
  line-height: 36px;
  border-radius: 36px;
}

.listing-rating-count-wrap .review-score {
  background: #384f95;
  color: #fff;
  float: left;
  font-weight: 600;
  border-radius: 4px;
  padding: 10px;
  font-size: 13px;
  margin-right: 12px;
}

.listing-rating-count-wrap .review-score span {
  position: relative;
  top: 4px;
  left: 10px;
  font-size: 14px;
  color: #fff;
}

.listing-rating-count-wrap .reviews-count {
  float: left;
  color: #fff;
  font-size: 11px;
  position: relative;
  top: 2px;
}

.category-name {
  float: left;
  margin-right: 10px;
  position: relative;
  color: #999;
  top: -2px;
  border-radius: 2px;
  background: #eee;
  padding: 4px 14px;
}

.geodir-category-opt {
  position: absolute;
  bottom: 68px;
  left: 12px;
  z-index: 3;
  cursor: pointer;
}

.listing_carditem .geodir-category-opt {
  padding: 10px 20px 0;
  bottom: 0;
}

.geodir-category-opt h4 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.geodir-category-opt h4 a {
  color: #fff;
}

.listing-rating {
  float: left;
  position: relative;
  top: 0;
}

.listing-rating i {
  font-size: 10px;
  margin-right: 4px;
  position: relative;
  z-index: 2;
}

.card-popup-rainingvis_bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 200px;
}

.card-popup-rainingvis_bg span {
  float: left;
  margin-right: 4px;
  position: relative;
}

.card-popup-rainingvis_bg span:before {
  font-family: Font Awesome\5 Pro;
  content: "\f005";
  font-size: 10px;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 700;
}

.geodir-category-content-title {
  padding: 2px 20px 20px;
  margin-bottom: 10px;
}

.geodir-category-content-title-item {
  float: left;
  width: 100%;
  position: relative;
  z-index: 1;
}

.facilities-list {
  margin: 10px 0;
}

.facilities-list-title {
  float: left;
  margin-right: 10px;
  color: #566985;
  font-weight: 700;
}

.facilities-list li {
  float: left;
  margin-right: 14px;
  cursor: pointer;
  position: relative;
}

.facilities-list li i {
  font-size: 16px;
}

.facilities-list li:hover i {
  color: #adc7dd;
}

.listing-item-category {
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  float: left;
  border-radius: 100%;
  position: relative;
}

.listing-item-category-wrap {
  float: left;
  position: relative;
}

.listing-item-category-wrap span {
  float: left;
  margin-left: 10px;
  position: relative;
  top: 10px;
  color: #7d93b2;
}

.geodir-category-footer {
  margin: 4px 0 0 0;
  padding: 10px 20px;
  border-top: 1px solid #eee;
}

.geodir-category-location a {
  float: left;
  color: #7d93b2;
  text-align: left;
  font-size: 12px;
}

.geodir-category-location a i {
  padding-right: 6px;
}

.geodir-opt-list {
  float: right;
  position: relative;
  top: 8px;
}

.geodir-opt-list:before {
  content: "";
  position: absolute;
  left: 0;
  top: -16px;
  bottom: -20px;
  width: 1px;
  background: #eee;
}

.geodir-category-text {
  padding: 0 20px;
}

.geodir-opt-list li {
  float: left;
  margin-left: 16px;
}

.geodir-opt-list a,
.gdop-list-link {
  float: left;
  position: relative;
  font-size: 16px;
  color: #9ea6ba;
  cursor: pointer;
}

.geodir-opt-list a:hover i,
.gdop-list-link:hover i,
.header-sec-link a:hover i,
.hero-categories li a:hover i {
  transition: all 0.2s ease-in-out;
  animation: bounce 1s;
}

@keyframes bounce {

  0%,
  20%,
  60%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  80% {
    transform: translateY(-5px);
  }
}

.geodir-opt-link {
  padding-right: 6px;
}

.geodir-opt-link a {
  float: left;
  height: 36px;
  line-height: 36px;
  color: #fff;
  background: #ecf6f8;
  width: 100%;
  font-weight: 500;
  border-radius: 4px;
  text-align: left;
  padding-left: 20px;
}

.geodir-opt-link a:hover {
  background: #18458b;
}

.geodir-opt-link a i {
  position: absolute;
  right: 0;
  width: 40px;
  height: 36px;
  line-height: 36px;
  top: 0;
  font-size: 8px;
  border-left: 1px solid rgba(255, 255, 255, 0.21);
  text-align: center;
}

.geodir-opt-link a:hover {
  color: #fff;
}

.geodir-opt-tooltip,
.price-name-tooltip {
  position: absolute;
  opacity: 0;
  right: 0;
  top: -40px;
  height: 30px;
  line-height: 30px;
  min-width: 100px;
  margin-left: -65px;
  color: #fff;
  font-size: 11px;
  visibility: hidden;
  border-radius: 2px;
  background: #313e6e;
}

.price-name-tooltip {
  right: -35px;
}

.geodir-opt-tooltip strong {
  color: #fff;
  padding-left: 6px;
}

.geodir-opt-list li:hover .geodir-opt-tooltip,
.geodir-category_price:hover .price-name-tooltip {
  opacity: 1;
  visibility: visible;
}

.geodir-category_contacts {
  position: absolute;
  right: 20px;
  bottom: 70px;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.15) !important;
  z-index: 20;
  max-width: 250px;
  border-radius: 4px;
  border: 1px solid #eee;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.geodir-category_contacts.visgdcc {
  opacity: 1;
  visibility: visible;
}

.close_gcc {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 3;
  font-size: 14px;
  color: #9ea6ba;
  cursor: pointer;
}

.geodir-category_contacts ul {
  position: relative;
  z-index: 2;
}

.geodir-category_contacts li {
  float: left;
  width: 100%;
  margin: 5px 0;
  text-align: left;
}

.geodir-category_contacts li span,
.geodir-category_contacts li a {
  float: left;
  color: #7d93b2;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
}

.geodir-category_contacts li span {
  margin-right: 10px;
  color: #9ea6ba;
}

.geodir-category_contacts li span i {
  margin-right: 6px;
}

.geodir-category_price {
  float: right;
  margin-right: 16px;
  position: relative;
  top: 13px;
}

.geodir-category_price .price-level-item strong {
  float: left;
  font-size: 11px;
  margin-left: 2px;
  color: #999;
}

/*-------------pagination---------------------------------------*/
.pagination {
  margin-top: 30px;
  text-align: center;
  float: left;
  width: 100%;
}

.pagination a {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 6px;
  border: 1px solid #eee;
  box-sizing: border-box;
  position: relative;
  font-size: 13px;
  color: #888da0;
  background: #fff;
}

.pagination a.current-page,
.pagination a:hover {
  color: #fff;
}

.pagination a i {
  font-size: 12px;
}

.pagination a.prevposts-link {
  margin-right: 20px;
}

.pagination a.nextposts-link {
  margin-left: 20px;
}

.pagination a.prevposts-link,
.pagination a.nextposts-link {
  width: auto;
  padding: 0 30px;
}

.pagination a.prevposts-link span {
  float: right;
  margin-left: 10px;
}

.pagination a.nextposts-link span {
  float: left;
  margin-right: 10px;
}

/*-------------listing-features---------------------------------------*/
.listing-features li {
  float: left;
  text-align: left;
  margin: 0 15px 10px 0;
  font-size: 12px;
  color: #596985;
  font-weight: 500;
  position: relative;
  padding-left: 25px;
}

.listing-features li i {
  position: absolute;
  left: 0;
  top: 0;
}

.listing-item-grid_container {
  margin-bottom: 50px;
}

.listing-item-grid {
  padding: 130px 30px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.listing-item-grid .d-gr-sec:before {
  opacity: -0.2;
}

.listing-item-grid_title {
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  text-align: left;
}

.listing-item-grid_title h3,
.listing-item-grid_title h3 a,
.listing-item-grid:hover p {
  color: #fff;
}

.listing-item-grid_title p {
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease-in-out 0s;
}

.listing-item-grid_title h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-size: 22px;
  position: relative;
}

.listing-item-grid_title h3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 2px;
}

.listing-item-grid .listing-counter {
  position: absolute;
  left: 20px;
  top: 30px;
  z-index: 10;
  padding: 8px 16px;
  z-index: 3;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.3);
}

.listing-item-grid .listing-counter span {
  padding-right: 6px;
}

.listing-slider-wrap {
  z-index: 20;
}

.listing-slider .listing-item {
  width: 100%;
  padding: 0;
  height: auto !important;
  transition: all 0.3s ease-in-out 0s;
}

.listing-slider-item {
  padding: 0 0 15px 0;
}

/* ------listing-single ------------------------------------------------------------ */
.listing-carousel-wrap {
  height: 500px;
}

.listing-carousel_pagination {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 20px;
  z-index: 100;
}

.hero_pagination {
  bottom: 0;
  z-index: 100;
}

.listing-carousel_pagination-wrap {
  display: inline-block;
  padding: 6px 20px 10px;
  border-radius: 20px;
  background: rgba(6, 27, 65, 0.4);
}

.hero_pagination .listing-carousel_pagination-wrap {
  padding: 6px 60px 6px;
  background: #fff;
  position: relative;
  border-radius: 30px 30px 0 0;
}

.listing-carousel_pagination .swiper-pagination-bullet,
.ss-slider-pagination .swiper-pagination-bullet {
  margin: 0 4px;
  width: 6px;
  height: 6px;
  background: #fff;
  opacity: 0.4;
}

.hero_pagination .listing-carousel_pagination-wrap .swiper-pagination-bullet {
  background: #ccc;
  opacity: 0.8;
}

.listing-carousel_pagination .swiper-pagination-bullet.swiper-pagination-bullet-active,
.ss-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}

.listing-carousel-button {
  position: absolute;
  top: 50%;
  width: 80px;
  height: 50px;
  line-height: 50px;
  margin-top: -25px;
  z-index: 100;
  cursor: pointer;
  background: #384f95;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.45);
  transition: all 200ms linear;
}

.listing-carousel-button.listing-carousel-button-next,
.listing-carousel-button.listing-carousel-button-next2 {
  right: -30px;
  padding-right: 20px;
  border-radius: 60px 0 0 60px;
}

.listing-carousel-button.listing-carousel-button-prev,
.listing-carousel-button.listing-carousel-button-prev2 {
  left: -30px;
  padding-left: 20px;
  border-radius: 0 60px 60px 0;
}

.listing-carousel-button.listing-carousel-button-next:hover,
.listing-carousel-button.listing-carousel-button-next2:hover {
  right: -15px;
  background: rgba(6, 27, 65, 0.4);
}

.listing-carousel-button.listing-carousel-button-prev:hover,
.listing-carousel-button.listing-carousel-button-prev2:hover {
  left: -15px;
  background: rgba(6, 27, 65, 0.4);
}

.listing-carousel-wrap .swiper-slide img {
  width: auto !important;
  height: 100% !important;
  position: relative;
  z-index: 1;
}

.listing-carousel-wrap .swiper-slide {
  width: auto !important;
  height: 100% !important;
  display: inherit !important;
}

.box-media-zoom {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  color: #fff;
  z-index: 10;
  top: 20px;
  right: 20px;
  font-size: 12px;
  transition: all 300ms linear;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
}

/*-------------scroll nav---------------------------------------*/
.scroll-nav-wrapper {
  background: #fff;
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid #eee;
}

.scroll-nav {
  float: left;
  border-right: 1px solid #eee;
}

.scroll-nav li {
  float: left;
  margin-right: 15px;
}

.scroll-nav li a {
  display: block;
  padding: 25px 20px 25px 0;
  position: relative;
  font-size: 10px;
  color: #7d93b2;
  font-weight: 800;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.scroll-nav li a i {
  margin-right: 10px;
  font-size: 13px;
  position: relative;
  top: 2px;
  font-weight: 600;
  color: #e5e5e5;
}

.scroll-nav li a:before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 2px;
}

.scroll-nav li a.act-scrlink:before {
  width: 100%;
}

.scroll-nav-wrapper-opt {
  float: right;
  position: relative;
  top: 12px;
}

.scroll-nav-wrapper-opt a.scroll-nav-wrapper-opt-btn {
  float: left;
  margin-left: 10px;
  padding: 8px 20px;
  border: 1px solid transparent;
  background: #425998;
  color: #fff;
  font-size: 12px;
  border-radius: 2px;
}

.scroll-nav-wrapper-opt a.scroll-nav-wrapper-opt-btn:hover {
  border: 1px solid #e7e7ed;
  color: #70778b;
  background: #fff;
}

.scroll-nav-wrapper-opt a.scroll-nav-wrapper-opt-btn i,
.show-more-snopt-tooltip a i {
  margin-right: 6px;
}

.h-btn:hover {
  color: #D53c2c !important;
}

.show-more-snopt {
  float: left;
  font-size: 28px;
  margin-left: 20px;
  color: #70778b;
  cursor: pointer;
}

.show-more-snopt-tooltip {
  position: absolute;
  right: 0;
  width: 150px;
  background: #fff;
  top: 100%;
  margin-top: 30px;
  z-index: 10;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.15);
  transition: all 300ms linear;
  opacity: 0;
  visibility: hidden;
}

.show-more-snopt-tooltip.show-more-snopt-tooltip_vis {
  margin-top: 20px;
  opacity: 1;
  visibility: visible;
}

.show-more-snopt-tooltip a {
  display: block;
  border-bottom: 1px solid #e7e7ed;
  padding: 10px;
  text-align: left;
  font-size: 11px;
  color: #7d93b2;
  font-weight: 600;
}

.show-more-snopt-tooltip a:last-child {
  border-bottom: 0;
}

.no-top-padding {
  padding: 30px 0 50px;
}

.bg-parallax-wrap,
.bg-tabs-wrap {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
  background: #ccc;
}

.bg-parallax-wrap {
  bottom: -50px;
}

.listing-hero-section {
  padding: 250px 0 0;
}

.listing-hero-section .bg {
  height: 120%;
}

.listing-hero-section .slideshow-container .bg,
.bg.par-elem {
  height: 110%;
  top: -5%;
}

.listing-hero-section .bg-parallax-wrap:before,
.d-gr-sec:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  background: linear-gradient(to bottom,
      rgba(6, 27, 65, 0) 0%,
      rgba(6, 27, 65, 0.95) 100%);
}

.d-gr-sec:before {
  z-index: 2;
}

.block_box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/*-------------breadcrumbs---------------------------------------*/
.breadcrumbs {
  z-index: 50;
  position: relative;
}

.breadcrumbs.inline-breadcrumbs {
  margin-bottom: 25px;
}

.p-0 {
  padding: 0px !important;
}

.border-none {
  border: none !important;

}

.brd-show-share {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 50%;
  padding: 0px 20px;
  height: 30px;
  line-height: 30px;
  margin-top: -15px;
  color: #fff;
  z-index: 20;
  font-size: 12px;
  border-radius: 4px;
}

.share-holder.sing-page-share {
  z-index: 20;
  bottom: -100px;
}

.top_sing-page-share {
  bottom: inherit;
  top: 100px;
}

.brd-show-share i {
  padding-right: 6px;
}

.top-breadcrumbs {
  padding: 25px 0;
  background: #fff;
}

.breadcrumbs.block-breadcrumbs {
  background: #fff;
  border: 1px solid #e5e7f2;
  border-top: none;
  padding: 25px 30px;
  border-radius: 0 0 6px 6px;
}

.breadcrumbs.inline-breadcrumbs:before,
.top-breadcrumbs .container:before {
  font-family: Font Awesome\5 Pro;
  content: "\f14c";
  position: absolute;
  right: 30px;
  top: 50%;
  height: 22px;
  line-height: 22px;
  margin-top: -11px;
  font-size: 22px;
  font-weight: 100;
  color: #adc7dd;
}

.top-breadcrumbs .container:before {
  margin-top: -2px;
  right: 0;
}

.breadcrumbs a {
  position: relative;
}

.breadcrumbs.inline-breadcrumbs a,
.breadcrumbs.inline-breadcrumbs span,
.breadcrumbs.top-breadcrumbs a,
.breadcrumbs.top-breadcrumbs span {
  float: left;
  padding: 0 16px;
  position: relative;
  color: #888da0;
  font-size: 12px;
  font-weight: 600;
}

.breadcrumbs a:before,
.breadcrumbs.top-breadcrumbs a:before {
  font-family: Font Awesome\5 Pro;
  content: "\f0da";
  font-size: 11px;
  position: absolute;
  top: 50%;
  right: -4px;
  margin-top: -5px;
  width: 10px;
  height: 2px;
  font-weight: 800;
  z-index: 20;
}

.breadcrumbs.inline-breadcrumbs span {
  color: #999;
}

.breadcrumbs.inline-breadcrumbs a:first-child,
.dasboard-breadcrumbs a:first-child {
  padding-left: 0;
}

/*-------------listing single---------------------------------------*/
.list-single-header {
  margin-bottom: 20px;
  padding: 30px 30px 0;
}

.list-single-header-item {
  padding: 10px 0 25px;
}

.list-single-header-item .geodir-category-location a {
  margin-right: 12px;
}

.list-single-header-item h1 {
  text-align: left;
  font-weight: 600;
  padding-bottom: 18px;
  color: #566985;
  position: relative;
  font-size: 26px;
}

.listing-hero-section .list-single-header-item h1 {
  color: #fff;
  font-size: 36px;
  position: relative;
}

.listing-hero-section .list-single-header-item h1:before {
  content: "";
  position: absolute;
  left: 0;
  top: -30px;
  width: 50px;
  height: 3px;
  border-radius: 3px;
}

.listing-hero-section .list-single-header-item .geodir-category-location a {
  color: #fff;
  font-weight: 600;
}

.list-single-header_bottom {
  border-top: 1px solid #eee;
  padding: 15px 0;
  margin-top: 10px;
}

.listing-hero-section .list-single-header_bottom {
  margin: 20px 0;
  padding: 25px 0 15px;
  border-color: rgba(255, 255, 255, 0.1);
}

.listing-hero-section .listing-item-category-wrap span,
.listing-hero-section .list-single-author a {
  color: #fff;
}

.listing-hero-section .list-single-stats li span {
  border: 1px dashed rgba(255, 255, 255, 0.21);
  background: rgba(255, 255, 255, 0.11);
  color: #fff;
}

.listing-rating-count-wrap.single-list-count .reviews-count {
  color: #666;
  font-size: 12px;
}

.listing-rating-count-wrap.single-list-count .review-score {
  padding: 14px;
  font-size: 18px;
  border-radius: 100%;
}

.list-single-header-column {
  padding: 6px 6px 6px 8px;
  border-radius: 70px;
  background: #f9f9f9;
}

.listing-hero-section .list-single-header-column {
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
  color: #fff;
  background: rgba(6, 27, 65, 0.25);
  top: 25px;
}

.listing-hero-section .list-single-header-column:hover {
  background: rgba(6, 27, 65, 0.45);
}

.listing-hero-section .list-single-header-column:before {
  display: none;
}

.list-single-header-column:after {
  opacity: 0.4;
}

.listing-hero-section .listing-rating-count-wrap.single-list-count .reviews-count {
  color: #fff;
}

.list-single-header-column:after {
  font-family: Font Awesome\5 Pro;
  content: "\f4b6";
  font-size: 11px;
  position: absolute;
  bottom: 10px;
  right: 14px;
  font-weight: 800;
  color: #eee;
  font-size: 34px;
}

.list-single-header-column:before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  width: 1px;
  height: 100px;
  margin-top: -50px;
  background: #eee;
}

.list-single-stats {
  float: right;
}

.list-single-stats li {
  float: left;
  margin-left: 10px;
}

.list-single-stats li span {
  display: block;
  padding: 8px 15px;
  border: 1px solid #e7e7ed;
  color: #70778b;
  font-size: 12px;
  border-radius: 4px;
  background: #fff;
}

.list-single-stats li span i {
  margin-right: 10px;
}

.list-single-author {
  float: left;
  font-size: 14px;
  margin-left: 20px;
  position: relative;
  top: 8px;
}

.author_avatar {
  float: left;
  margin-right: 10px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  top: -8px;
}

.author_avatar img,
.author_avatar {
  width: 36px;
  height: 36px;
}

.list-single-author a {
  color: #7d93b2;
  font-size: 12px;
}

.list-single-header_bottom .geodir_status_date {
  position: relative;
  top: 2px;
  left: 0;
  float: left;
  margin-left: 16px;
  padding: 8px 10px;
}

.list-single-main-item p,
.accordion-inner p,
.author-content p,
.about-wrap p,
.user-profile-header_content p {
  text-align: left;
  color: #878c9f;
  font-size: 12px;
  line-height: 24px;
  padding-bottom: 10px;
}

.simple-title {
  font-size: 14px;
  padding-bottom: 10px;
  font-weight: 600;
  color: #666;
}

.list-single-main-item {
  margin-bottom: 20px;
}

.list-single-main-item-title {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding: 18px 30px;
  position: relative;
}



.list-single-main-item_content {
  padding: 10px 30px 30px;
}

.tags-stylwrap .tags-title {
  float: left;
  margin-right: 18px;
  color: #7d93b2;
  font-weight: 500;
  position: relative;
  top: 10px;
  font-size: 12px;
}

.tags-stylwrap .tags-title i {
  margin-right: 6px;
}

.tags-stylwrap a {
  float: left;
  padding: 8px 20px;
  border-radius: 2px;
  color: #888da0;
  font-weight: 400;
  margin: 0 6px 6px 0;
  background: #f5f6fa;
  border: 1px solid #eee;
  transition: all 0.2s ease-in-out;
}

.tags-stylwrap a:hover {
  border-color: transparent;
  color: #fff;
}

.rooms-item {
  margin-bottom: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.rooms-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.rooms-media {
  float: left;
  width: 35%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.rooms-media img {
  width: 100%;
  height: auto;
}

.rooms-details {
  float: left;
  width: 65%;
  padding: 5px 0 10px 20px;
}

.rooms-details-header {
  text-align: left;
  padding-bottom: 20px;
}

.rooms-price {
  position: absolute;
  right: 0;
  top: 0;
  color: #5ecfb1;
  font-size: 19px;
  font-weight: 600;
}

.rooms-price strong {
  padding-left: 3px;
  font-size: 10px;
  color: #999;
}

.rooms-details-header h3 {
  color: #334e6f;
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 700;
}

.rooms-details-header h5 {
  font-size: 11px;
  font-weight: 700;
  color: #666;
}

.rooms-details-header h5 span {
  color: #7d93b2;
}

.jr-insta-thumb {
  float: left;
  width: 100%;
}

.jr-insta-thumb a {
  float: left;
  width: 33.3%;
  padding: 5px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.jr-insta-thumb a img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.jr-insta-thumb a:hover {
  opacity: 0.6;
}

.box-widget-item-header {
  position: relative;
  background: #fff;
  padding: 10px 30px;
  border-radius: 10px 10px 0 0;
}

.x-icon:hover {
  color: gray;
}

.list-single-main-item_subtitle {
  padding: 15px 0 25px;
}

.box-widget-item-header h3,
.list-single-main-item-title h3,
.list-single-main-item_subtitle h4 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #566985;
  font-family: "Nunito", sans-serif;
}

.list-single-main-item_subtitle h4 {
  font-size: 14px;
}

.pricerange span {
  float: left;
  margin-right: 20px;
  color: #878c9f;
  font-weight: 700;
  font-size: 14px;
  position: relative;
  top: 6px;
}

.pricerange {
  font-size: 22px;
  text-align: left;
  font-weight: 400;
}

.claim-widget-link {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #eee;
}

.claim-widget-link span,
.claim-widget-link a {
  float: left;
  text-align: left;
  font-weight: 500;
}

.claim-widget-link a:hover {
  color: #24324f;
}

.claim-widget-link span {
  color: #878c9f;
  margin-right: 20px;
}

.fw-separator {
  float: left;
  width: 100%;
  margin: 15px 0;
  height: 1px;
  background: #eee;
}

/*-------------widgets---------------------------------------*/
.box-widget-content {
  float: left;
  width: 100%;
  position: relative;
  padding: 0px 30px 30px;
}

.bwc-nopad {
  padding: 25px 0 0;
}

.bwc-padside {
  padding-left: 30px;
  padding-right: 30px;
}

.box-widget-item,
.list-single-facts {
  margin-bottom: 20px;
}

.inline-facts-wrap {
  display: block;
  padding: 20px 30px 30px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.single-facts .inline-facts-wrap {
  float: left;
  width: 25%;
}

.inline-facts-wrap h6 {
  width: 100%;
  float: left;
  font-size: 12px;
  text-align: left;
  color: #fff;
  font-weight: 400;
  margin-top: 6px;
}

.inline-facts-wrap i {
  position: absolute;
  right: 30px;
  top: 10px;
  font-size: 44px;
  color: #fff;
  opacity: 0.4;
}

.inline-facts-wrap .num {
  float: left;
  font-size: 36px;
  color: #fff;
  font-weight: 800;
}

.single-facts_2 .inline-facts-wrap .num,
.bold-facts .inline-facts-wrap .num {
  color: #fff;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  font-size: 44px;
  float: none;
  text-align: center;
}

.bold-facts .inline-facts-wrap {
  padding: 0;
  margin-top: 15px;
}

.single-facts_2 .inline-facts-wrap h6,
.bold-facts .inline-facts-wrap h6 {
  font-weight: 600;
  color: #fff;
  font-size: 13px;
  margin-top: 12px;
  text-align: center;
}

.bold-facts .inline-facts-wrap h6 {
  color: #7d93b2;
}

.single-facts_2 .inline-facts-wrap:before {
  content: "";
  position: absolute;
  bottom: 6px;
  width: 30px;
  height: 2px;
  left: 50%;
  margin-left: -15px;
  background: rgba(255, 255, 255, 0.4);
}

.single-facts_2 .inline-facts-wrap:after {
  content: "";
  position: absolute;
  right: -100px;
  width: 100px;
  height: 1px;
  border-top: 1px dotted rgba(255, 255, 255, 0.8);
  top: 50%;
}

.bold-facts .inline-facts-wrap:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 1px;
  background: #ccc;
  top: 50%;
  right: 0;
}

.single-facts_2 .inline-facts-wrap:last-child:after,
.bold-facts .inline-facts-wrap:last-child:before {
  display: none;
}

.list-single-main-item_content .listing-features li {
  padding-left: 0;
}

.list-single-main-item_content .listing-features li a {
  font-weight: 600;
  color: #878c9f;
  font-family: "Nunito", sans-serif;
  font-size: 12px;
}

.list-single-main-item_content .listing-features li i {
  width: 30px;
  height: 30px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  background: #f5f6fa;
  position: relative;
  float: left;
  margin-right: 10px;
  top: -8px;
  font-size: 11px;
}

.stat-wave {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100px;
  opacity: 0.2;
  bottom: 0;
}

.stat-wave svg {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: -30px;
}

.opening-hours ul li {
  width: 100%;
  float: left;
  padding: 12px 0;
  color: #878c9f;
  position: relative;
  border-bottom: 1px solid #eee;
  font-size: 13px;
}

.opening-hours ul li:first-child {
  padding-top: 0;
}

.opening-hours ul li span.opening-hours-time {
  float: right;
  font-weight: 500;
  color: #7d93b2;
}

.opening-hours ul li span.opening-hours-day {
  float: left;
  color: #878c9f;
  font-weight: 600;
}

.list-author-widget-contacts {
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  float: left;
  width: 100%;
}

.list-author-widget-contacts p {
  text-align: left;
}

.list-author-widget-contacts.list-item-widget-contacts {
  margin-top: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.list-author-widget-contacts li {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.list-author-widget-contacts.list-item-widget-contacts li {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.list-author-widget-contacts.list-item-widget-contacts li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.list-author-widget-contacts li span,
.list-author-widget-contacts li a {
  float: left;
  text-align: left;
  font-weight: 700;
  font-size: 14px;
}

.list-author-widget-contacts li span {
  min-width: 80px;
}

.list-author-widget-contacts li span i {
  margin-right: 7px;
  font-size: 14px;
}

.list-author-widget-contacts li a {
  margin-left: 20px;
  color: #3a424a;
  font-family: "Quicksand";
}



.bottom-bcw-box {
  margin-top: 10px;
  padding: 12px 70px 12px 30px;
  border-top: 1px solid #eee;
}

.bottom-bcw-box_link {
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid #eee;
  height: 100%;
  width: 70px;
}

.bottom-bcw-box_link a {
  position: absolute;
  width: 100%;
  height: 20px;
  left: 0;
  top: 50%;
  font-size: 16px;
  color: #9ea6ba;
  margin-top: -10px;
}

.list-widget-social li {
  float: left;
}

.list-widget-social li {
  margin-right: 8px;
}

.list-widget-social li a {
  width: 30px;
  height: 30px;
  display: block;
  line-height: 30px;
  border-radius: 4px;
  color: #fff;
}

.box-widget-author-title {
  padding: 20px 150px 30px 30px;
  position: relative;
}

.box-widget-author-title_content a {
  text-align: left;
  color: #566985;
  display: block;
  position: relative;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 600;
  padding-bottom: 6px;
}

.box-widget-author-title_content span {
  display: block;
  font-size: 10px;
  text-align: left;
  color: #878c9f;
  text-transform: uppercase;
}

.box-widget-author-title-img {
  float: left;
  margin-right: 18px;
}

.box-widget-author-title-img img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.box-widget-author-title_opt {
  position: absolute;
  right: 30px;
  top: 50%;
  height: 50px;
  margin-top: -25px;
  border-radius: 50px;
}

.box-widget-author-title_opt:before {
  content: "";
  position: absolute;
  background: #f9f9f9;
  border-radius: 70px 0 0 70px;
  top: -6px;
  bottom: -6px;
  left: -20px;
  right: -30px;
  border: 1px solid #eee;
  border-right: none;
}

.box-widget-author-title_opt a {
  width: 34px;
  height: 34px;
  float: left;
  border-radius: 100%;
  position: relative;
  margin-right: 8px;
  color: #fff;
  top: 8px;
}

.box-widget-author-title_opt a:last-child {
  margin-right: 0;
}

.box-widget-author-title_opt a i {
  line-height: 34px;
}

.counter-widget .countdown-item {
  float: left;
  color: #fff;
  margin-right: 20px;
  text-align: left;
  position: relative;
}

.counter-widget .countdown-item:before {
  content: ":";
  position: absolute;
  right: -12px;
  top: 6px;
  font-size: 20px;
  color: #fff;
}

.counter-widget .countdown-item:last-child:before {
  display: none;
}

.counter-widget .countdown-item span {
  font-weight: 600;
  font-size: 28px;
}

.counter-widget .countdown-item p {
  font-size: 11px;
  opacity: 0.7;
}

.counter-widget {
  float: left;
  margin-left: 40px;
  position: relative;
  top: -8px;
}

.counter-widget:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 20px;
  top: 50%;
  left: -20px;
  margin-top: -10px;
  background: rgba(255, 255, 255, 0.2);
}

.reviews-comments-item-text .review-score-user {
  position: absolute;
  top: -8px;
  right: 0;
}

.reviews-comments-item-text .review-score-user span.review-score-user_item {
  background: #3d4e94;
  color: #fff;
  float: right;
  font-weight: 600;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.21);
  margin-left: 20px;
}

.reviews-comments-item-text .review-score-user .listing-rating {
  top: 10px;
}

.reviews-comments-item {
  padding: 0 0 20px 90px;
  position: relative;
  float: left;
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid #eee;
}

.reviews-comments-item:last-child {
  padding-bottom: 0;
}

.reviews-comments-item.reply-comment-item {
  width: 90%;
  margin-left: 10%;
  padding: 30px 20px 30px 90px;
  border-radius: 6px;
}

.reviews-comments-item.reply-comment-item .review-comments-avatar {
  top: 30px;
}

.reviews-comments-item.reply-comment-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: -10%;
  margin-left: 30px;
  width: 40px;
  height: 40px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.reviews-comments-item:last-child {
  border: none;
  margin-bottom: 0;
}

.review-comments-avatar {
  position: absolute;
  top: 0;
  left: 10px;
  width: 60px;
  height: 60px;
}

.review-comments-avatar:before {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  border-color: #fff;
  border-right-color: #f5f6fa;
  border-width: 10px;
  margin-top: -10px;
  pointer-events: none;
}

.review-comments-avatar img {
  width: 60px;
  height: 60px;
  float: left;
  border-radius: 100%;
}

.reviews-comments-item-text {
  padding: 20px 20px 15px;
  background: #f5f6fa;
  border-radius: 6px;
}

.reviews-comments-item-text .new-dashboard-item {
  top: 0;
}

.reviews-comments-item-text h4 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #566985;
}

.reviews-comments-item-text h4 a {
  color: #566985;
}

.reviews-comments-item-text p {
  text-align: left;
}

.reviews-comments-header {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.reviews-comments-item-footer {
  padding: 15px 0 0;
  margin-top: 15px;
  border-top: 1px solid #e5e7f2;
}

.reviews-comments-item-date {
  float: left;
  position: relative;
  top: 8px;
  color: #878c9f;
}

.only-comments .reviews-comments-item-date {
  top: 0;
}

.reviews-comments-item-date a.reply-link {
  float: right;
  font-weight: 400;
  color: #566985;
}

.reviews-comments-item-date span i,
.reviews-comments-item-date a i {
  margin-right: 10px;
}

.reply-item {
  position: absolute;
  right: 0;
  top: 12px;
  padding: 5px 15px;
  border-radius: 30px;
  color: #fff;
  z-index: 10;
  font-size: 11px;
}

.rate-review {
  float: right;
  padding: 8px 20px;
  border: 1px solid #e5e7f2;
  border-radius: 30px;
  background: #fff;
  font-size: 11px;
  font-weight: 400;
  color: #566985;
}

.rate-review:hover {
  background: #f9f9f9;
}

.rate-review i {
  margin-right: 6px;
}

.rate-review span {
  float: right;
  margin-left: 6px;
  color: #666;
  font-weight: 600;
}

.review-images {
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
}

.review-images a {
  flex: 4;
  max-width: 25%;
  margin-right: 15px;
  border-radius: 4px;
  overflow: hidden;
}

.review-images a img {
  width: 100%;
  height: auto;
}

.reviews-score-wrap {
  padding: 20px 40px 25px;
  border-bottom: 1px solid #eee;
  background: #f9f9f9;
  top: -20px;
}

.list-single-hero-header .reviews-score-wrap {
  padding: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.review-score-total {
  position: absolute;
  left: 20px;
  width: 100px;
  height: 60px;
  top: 28px;
}

.review-score-total span.review-score-total-item {
  background: #384f95;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  border-radius: 4px;
  width: 70px;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  position: relative;
  left: -2px;
}

.review-score-total .listing-rating {
  float: none;
  display: inline-block;
  margin-top: 4px;
}

.review-score-detail {
  width: 100%;
  padding-left: 110px;
}

.review-score-total a {
  float: left;
  width: 100%;
  padding: 10px 0;
  color: #fff;
  margin-top: 10px;
  border-radius: 4px;
}

.review-score-total span strong {
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding-top: 6px;
}

.reviews-comments-wrap {
  display: inline-table;
  margin-top: 20px;
}

.review-score-detail-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.review-score-detail-list .rate-item {
  margin: 5px 0;
  width: 50%;
  padding-right: 10px;
  position: relative;
}

.rate-item-title {
  margin-bottom: 8px;
  text-align: left;
  color: #fff;
  font-size: 12px;
  float: left;
  min-width: 100px;
}

.review-score-detail-list .rate-item-title,
.review-score-detail-list .rate-item-percent {
  color: #7d93b2;
}

.rate-item-bg {
  height: 6px;
  background: #fff;
  border-radius: 8px;
  float: left;
  width: calc(100% - 30px);
  overflow: hidden;
}

.review-score-detail-list .rate-item-bg {
  background: #e2e2e2;
  height: 10px;
}

.rate-item-line {
  float: left;
  height: 6px;
  border-radius: 4px;
}

.review-score-detail-list .rate-item-line {
  height: 10px;
}

.rate-item-percent {
  position: absolute;
  top: 18px;
  right: 10px;
  color: #fff;
}

.review-score-form {
  padding: 30px 30px 10px;
  border-bottom: 1px solid #eee;
  background: #f9f9f9;
  top: -20px;
  z-index: 1;
}

.review-total,
.review-range-container {
  float: left;
}

.review-total {
  width: 15%;
  padding: 15px 0;
  background: #fff;
  top: 26px;
  border: 1px solid #eee;
  border-right: none;
  box-sizing: border-box;
  border-radius: 38px 0 0 38px;
  position: relative;
  left: 31px;
  overflow: hidden;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.03);
}

.review-total strong {
  display: block;
  margin-top: 10px;
  color: #878c9f;
  font-size: 11px;
  font-weight: 400;
}

.custom-form .review-total span input {
  font-size: 26px;
  border: none;
  padding: 0;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1px;
  background: none;
  cursor: default;
  color: #566985;
}

.custom-form .clear-singleinput {
  bottom: 34px;
}

.review-range-container {
  width: 85%;
  padding-right: 20px;
}

.review-range-item {
  display: block;
  margin-bottom: 10px;
}

.review-range-item .range-slider-title {
  width: 20%;
  position: relative;
  text-align: left;
  top: -4px;
  color: #878c9f;
}

.review-range-item .range-slider-wrap {
  width: 80%;
  margin-top: 0;
  position: relative;
  top: -24px;
}

.review-range-item .range-slider-title,
.review-range-item .range-slider-wrap {
  float: left;
}

.fuzone {
  position: relative;
  border: 1px dotted #ccc;
  background: #f5f6fa;
  transition: all 0.3s linear;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  min-height: 100px;
  margin-top: 20px;
  float: left;
  cursor: pointer;
  border-radius: 4px;
}

.fuzone input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
}

.fuzone .fu-text {
  text-align: center;
  margin: 30px 0;
  font-size: 11px;
  font-weight: 600;
  color: #878c9f;
  position: relative;
}

.fuzone .fu-text i {
  font-size: 44px;
  width: 100%;
  padding-bottom: 10px;
  transition: all 0.3s linear;
  color: #666;
}

.fuzone:hover .fu-text i {
  transform: translateY(-10px);
}

.photoUpload-files {
  margin-top: 6px;
  padding: 0 10px;
}

.photoUpload-files span {
  color: #666;
  margin: 0 3px;
  padding: 3px 6px;
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  font-size: 10px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.07);
}

.list-single-main-media {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.list-single-main-media img {
  width: 100%;
  height: auto;
}

.promo-link {
  position: absolute;
  bottom: 40px;
  left: 50px;
  padding: 16px 55px 16px 70px;
  color: #7d93b2;
  border-radius: 30px;
  font-size: 11px;
  font-weight: 700;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.4);
  z-index: 10;
  background: #fff;
}

.promo-link i {
  position: absolute;
  left: 10px;
  top: 50%;
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 100%;
  margin-top: -17px;
  color: #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.25);
}

.promo-link:hover i {
  background: #2c3b5a;
  color: #fff;
}

.restor-menu-widget {
  margin-bottom: 20px;
}

.restmenu-item {
  float: left;
  position: relative;
  width: 50%;
  padding: 10px 20px 10px 84px;
  border-radius: 6px;
}

.restmenu-item:hover {
  background: #f9f9f9;
}

.restmenu-item-img {
  position: absolute;
  left: 0;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 100%;
  transition: all 200ms linear;
}

.restmenu-item-img img {
  height: 100%;
  width: auto;
  position: relative;
  left: 50%;
  margin-left: -60px;
}

.restmenu-item-img:hover {
  opacity: 0.7;
}

.restmenu-item-det-header {
  margin-bottom: 6px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #ccc;
}

.restmenu-item-det p {
  font-size: 11px;
  line-height: 16px;
}

.restmenu-item-det-header h4 {
  text-align: left;
  float: left;
  font-size: 14px;
  color: #566985;
}

.restmenu-item-det-price {
  float: right;
  color: #7d93b2;
  font-weight: 600;
  font-size: 14px;
}

.menu-filters,
.menu-filters a {
  float: left;
}

.menu-filters,
.listing-filters {
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 30px;
  border: 1px solid #eee;
}

.menu-filters a,
.listing-filters a {
  padding: 10px 30px;
  color: #7d93b2;
  font-size: 12px;
  font-weight: 600;
  border-right: 1px solid #eee;
}

.menu-filters a.menu-filters-active,
.listing-filters a.gallery-filter-active {
  color: #fff;
}

.menu-filters a:last-child {
  border-right: none;
}

.listing-filters {
  border: none;
  margin-top: 0;
}

.listing-filters a {
  display: inline-table;
  border: 1px solid #eee;
  border-right: none;
  margin: 0 -2px;
  padding: 15px 30px;
  background: #f9f9f9;
}

.listing-filters a:hover {
  background: #fff;
}

.listing-filters a:last-child {
  border-right: 1px solid #eee;
  border-radius: 0 6px 6px 0;
}

.listing-filters a:first-child {
  border-radius: 6px 0 0 6px;
}

/*------ Grid Gallery------------------------------------------------*/

.gallery-item {
  position: relative;
  float: left;
  padding: 5px;
}

.gallery-item .listing-item {
  width: 100%;
  padding: 0;
}

.four-column .gallery-item {
  width: 25%;
}

.four-column .gallery-item.gallery-item-second {
  width: 50%;
}

/*-------------Team---------------------------------------*/
.team-box {
  float: left;
  width: 25%;
  position: relative;
  padding: 10px 10px;
  cursor: pointer;
}


.team-boxat {
  float: left;
  width: 33.3%;
  position: relative;
  padding: 10px 10px;
  cursor: pointer;
}

.section-team .team-box {
  padding: 10px 8px;
}

.team-box2 .team-box {
  padding: 0 20px;
}

.team-photo {
  overflow: hidden;
  border-radius: 6px;
  margin-bottom: 10px;
}

.team-box2 .team-box .team-photo {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.08);
}


.team-info h3,
.team-info h4 {
  display: block;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 26px;
  color: #000;
  text-align: left;
  font-family: "Quicksand";
}

.team-info h4 {
  font-size: 11px;
}

.team-info h3 a {
  color: #666;
}

.team-social {
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-social i {
  font-size: 25px;
}

.team-social li {
  float: left;
  margin-right: 15px;
}

.team-social li:last-child {
  margin: 0;
}

.team-social li a:hover {
  color: #999;
}

.team-dec {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 26px;
  color: #adc7dd;
}

/*-------------banner---------------------------------------*/
.banner-wdget {
  overflow: hidden;
  border-radius: 6px;
}

.banner-wdget .overlay {
  opacity: 0.4;
}

.banner-wdget-content {
  padding: 80px 30px 40px;
  z-index: 10;
}

.banner-wdget-content h4 {
  font-weight: 600;
  color: #fff;
  font-size: 14px;
}

.banner-wdget-content a {
  font-weight: 600;
  color: #fff;
  padding: 10px 30px;
  display: inline-table;
  margin-top: 25px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.back-tofilters {
  color: #fff;
  margin-top: 20px;
  padding: 15px 0;
  border-radius: 4px;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.15);
}

.back-tofilters i {
  margin-left: 10px;
}

.slideshow-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  z-index: 1;
}

.slide-progress-wrap {
  position: absolute;
  top: 70px;
  right: 50px;
  width: 80px;
  height: 1px;
  z-index: 100;
  background: rgba(255, 255, 255, 0.2);
}

.slide-progress {
  position: absolute;
  left: 0;
  top: -1px;
  width: 0;
  height: 3px;
  border-radius: 4px;
  background: #fff;
}

/*-------------faq---------------------------------------*/
.faq-nav li {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.faq-nav li a {
  display: block;
  position: relative;
  padding: 20px 20px;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}

.faq-nav li a.act-scrlink:before {
  font-family: Font Awesome\5 Pro;
  content: "\f105";
  position: absolute;
  top: 50%;
  font-size: 16px;
  right: 16px;
  color: #ccc;
  z-index: 2;
  height: 16px;
  line-height: 16px;
  margin-top: -8px;
}

.faq-nav li a.act-scrlink {
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.15);
}

.faq-nav li span {
  display: block;
  font-weight: 600;
  color: #7d93b2;
  font-size: 13px;
  padding-top: 10px;
}

.faq-nav li a i {
  font-size: 24px;
  color: #ccc;
}

.faq-title {
  margin: 20px 0;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 20px;
  text-align: left;
  font-weight: 600;
  color: #7d93b2;
  font-size: 15px;
  display: block;
}

.faq-links {
  margin-top: 40px;
}

.faq-title.faq-title_first {
  margin-top: 0;
}

.faq-links-title {
  display: block;
  color: #566985;
  font-size: 24px;
  font-weight: 700;
}

/*------profile------------------------------------------------*/
.user-profile-header {
  margin-bottom: 20px;
}

.user-profile-header_media {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  padding: 170px 0 20px 0;
  z-index: 1;
}

.user-profile-header_media:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 30%;
  background: linear-gradient(to bottom,
      rgba(6, 27, 65, 0) 0%,
      rgba(6, 27, 65, 0.95) 100%);
  z-index: 2;
}

.user-profile-header_content {
  background: #fff;
  border-radius: 0 0 10px 10px;
  padding: 50px 30px 30px;
  box-sizing: border-box;
  border: 1px solid #e5e7f2;
  border-top: none;
  z-index: 2;
}

.user-profile-header-avatar {
  position: absolute;
  left: 30px;
  top: -55px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.2);
}

.user-profile-header-avatar:before {
  content: "";
  position: absolute;
  left: -6px;
  top: -6px;
  right: -6px;
  bottom: -6px;
  background: #fff;
  z-index: 1;
  border-radius: 100%;
}

.user-profile-header-avatar img {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  position: relative;
  z-index: 3;
}

.user-profile-header_media_title {
  z-index: 2;
  float: left;
  position: relative;
  padding-left: 130px;
  text-align: left;
}

.user-profile-header_media_title h3 {
  font-size: 16px;
  padding-bottom: 8px;
}

.user-profile-header_media_title h3,
.user-profile-header_media_title h4 {
  color: #fff;
}

.follow-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 10px 30px;
  z-index: 20;
  color: #fff;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  cursor: pointer;
}

.follow-btn i {
  padding-left: 10px;
}

.user-profile-header_stats {
  float: right;
  position: relative;
  z-index: 2;
  padding-right: 30px;
  top: 15px;
}

.user-profile-header_stats li {
  float: left;
  color: #fff;
  position: relative;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.25);
}

.user-profile-header_stats li:first-child:before {
  display: none;
}

.user-profile-header_stats li:first-child {
  border-radius: 4px 0 0 4px;
}

.user-profile-header_stats li:last-child {
  border-radius: 0 4px 4px 0;
}

.user-profile-header_stats li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 30px;
  margin-top: -15px;
  background: rgba(255, 255, 255, 0.3);
}

.user-profile-header_stats li span {
  font-weight: 600;
  padding-right: 10px;
}

/*------chat-widget------------------------------------------------*/
.chat-widget-button {
  position: fixed;
  bottom: 110px;
  right: 50px;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  font-size: 17px;
  background: #425998;
  z-index: 116;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(0, 0, 0);
}

.chat-widget-button.closechat_btn i:before {
  content: "\f00d";
}

.chat-widget_wrap {
  position: fixed;
  bottom: 170px;
  right: 50px;
  width: 300px;
  height: 400px;
  z-index: 116;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: none;
  -webkit-transform: translate3d(0, 0, 0);
}

.chat-widget_header {
  height: 50px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  line-height: 50px;
  padding: 0 25px;
  background: #425998;
}

.chat-widget_header h3 {
  float: left;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.chat-widget_header h3 a {
  color: #fff;
}

.status {
  position: absolute;
  right: 20px;
  top: 25px;
  line-height: 0;
  color: #fff;
  padding-right: 12px;
}

.status span {
  position: absolute;
  right: 0;
  width: 6px;
  height: 6px;
  top: -2px;
  border-radius: 100%;
}

.chat-widget_input {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  height: 50px;
  padding-right: 60px;
  border-top: 1px solid #eee;
  background: #f9f9f9;
}

.chat-widget_input textarea {
  display: block;
  white-space: pre-wrap;
  width: 100%;
  border: none;
  background: #f9f9f9;
  font-size: 12px;
  padding: 17px 11px 13px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100px;
  resize: none;
}

.chat-widget_input button {
  position: absolute;
  right: 8px;
  width: 50px;
  height: 34px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  font-size: 14px;
  top: 7px;
  z-index: 2;
  color: #fff;
  cursor: pointer;
}

.chat-body {
  position: absolute;
  width: 100%;
  top: 50px;
  height: calc(100% - 100px);
  overflow: auto;
  left: 0;
  background: #fff;
  padding: 25px 20px;
}

.chat-body .simplebar-scrollbar:before {
  background: rgba(0, 0, 0, 0.2);
  width: 5px;
}

.chat-message {
  padding-left: 60px;
  margin-bottom: 30px;
}

.chat-message:last-child {
  margin-bottom: 0;
}

.chat-message p {
  float: left;
  padding: 10px 20px 15px;
  background: #f5f6fa;
  border-radius: 0 10px 10px 10px;
  width: 100%;
  text-align: left;
  font-size: 11px;
  color: #878c9f;
  position: relative;
}

.chat-message.chat-message_user p {
  border-radius: 10px 0 10px 10px;
  color: #fff;
}

.massage-date {
  float: left;
  font-size: 11px;
  color: #999;
  margin-bottom: 11px;
}

.massage-date span {
  position: relative;
  padding-left: 10px;
}

.chat-message.chat-message_user .massage-date {
  float: right;
}

.chat-message .dashboard-message-avatar {
  position: absolute;
  left: 0;
  top: 20px;
}

.chat-message .dashboard-message-avatar,
.chat-message .dashboard-message-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.chat-message.chat-message_user .dashboard-message-avatar {
  left: inherit;
  right: 0;
}

.chat-message-user-name {
  font-size: 11px;
  color: #999;
  position: absolute;
  left: 4px;
  top: 70px;
  font-weight: 600;
}

.chat-message.chat-message_user .chat-message-user-name {
  left: inherit;
  right: 8px;
}

.chat-message.chat-message_user {
  padding-left: 0;
  padding-right: 60px;
}

/*------ Video ------------------------------------------------*/
.media-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0;
  z-index: 1;
}

.video-container video {
  width: 100%;
}

.video-iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden !important;
  top: 0;
  left: 0;
  padding: 0 !important;
}

.video-iframe iframe {
  position: absolute;
  top: -75px;
  left: 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.media-container.video-parallax {
  height: 120%;
  top: -10%;
}

.resp-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
}

.resp-video iframe,
.resp-video object,
.resp-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-holder iframe {
  position: absolute;
  top: -75px;
  left: 50%;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}

.video-item h3 {
  font-size: 34px;
  color: #fff;
  float: left;
  width: 100%;
  font-weight: 700;
  padding-bottom: 20px;
}

.video-item p {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  color: #eee;
  text-align: left;
  font-size: 13px;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.video-item a {
  display: inline-table;
  margin-top: 30px;
}

/*------Blog------------------------------------------------*/
.post-article .list-single-main-media {
  margin-bottom: 0;
  border-radius: 10px 10px 0 0;
}

.post-article .list-single-main-item {
  padding: 30px 30px 20px;
  border-radius: 0 0 10px 10px;
}

.post-article .btn {
  float: right;
}

.post-opt-title {
  display: block;
  text-align: left;
  font-weight: 600;
  font-size: 21px;
  margin-bottom: 20px;
}

.post-opt-title,
.post-opt-title a {
  color: #566985;
}

.post-author {
  float: left;
  margin-right: 20px;
  margin-top: 6px;
}

.post-author img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  float: left;
  margin-right: 20px;
}

.post-author span {
  position: relative;
  top: 12px;
}

.post-opt {
  padding-top: 18px;
}

.post-opt,
.post-opt li,
.cat-item li {
  float: left;
}

.post-opt li {
  margin-right: 20px;
}

.post-opt li span,
.post-opt li a,
.post-author span {
  color: #7d93b2;
  font-weight: 400;
  font-size: 12px;
}

.post-opt li i {
  padding-right: 6px;
}

.cat-item li {
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 15px;
  text-align: left;
  position: relative;
}

.cat-item li:last-child {
  margin-bottom: 0;
}

.cat-item li:before {
  content: "";
  position: absolute;
  bottom: 6px;
  width: 100px;
  height: 1px;
  background: #eee;
  left: 50%;
  margin-left: -25px;
}

.cat-item li a {
  float: left;
  font-size: 12px;
  color: #7d93b2;
  font-weight: 400;
}

.cat-item li span {
  float: right;
  color: #999;
  font-size: 12px;
}

.search-widget input {
  float: left;
  width: 100%;
  border: none;
  background: #f5f6fa;
  height: 50px;
  padding: 0 80px 0 20px;
  z-index: 1;
  border-radius: 54px;
}

.search-widget .search-submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 50px;
  line-height: 50px;
  z-index: 2;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 0 54px 54px 0;
}

.single-post-article .post-opt-title {
  margin-bottom: 10px;
}

.post-article {
  position: relative;
}

.post-nav-wrap {
  margin-bottom: 20px;
}

.post-nav {
  float: left;
  width: 50%;
  position: relative;
  background: #f9f9f9;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid #e5e7f2;
}

.post-nav:before {
  position: absolute;
  height: 20px;
  line-height: 20px;
  top: 50%;
  margin-top: -10px;
  font-family: Font Awesome\5 Pro;
  font-size: 14px;
  transition: all 200ms linear;
}

.post-nav-prev:before {
  content: "\f104";
  right: 20px;
}

.post-nav-prev:hover:before {
  right: 30px;
}

.post-nav-next:before {
  content: "\f105";
  left: 20px;
}

.post-nav-next:hover:before {
  left: 30px;
}

.post-nav:hover {
  background: #fff;
}

.post-nav-img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: absolute;
  overflow: hidden;
  top: 50%;
  margin-top: -30px;
  box-shadow: 0 2px 16px rgba(58, 87, 135, 0.25);
}

.post-nav-img i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #fff;
  font-size: 24px;
}

.post-nav-img img {
  width: auto;
  height: 60px;
  margin: 0 auto;
}

.post-nav-prev .post-nav-img {
  left: 10px;
}

.post-nav-next .post-nav-img {
  right: 10px;
}

.post-nav-prev {
  padding-left: 90px;
  border-radius: 50px 0 0 50px;
  text-align: left;
  border-right: none;
}

.post-nav-next {
  padding-right: 90px;
  border-radius: 0 50px 50px 0;
  text-align: right;
}

.post-nav-text {
  font-size: 14px;
  font-weight: 500;
  color: #566985;
}

.post-nav-text strong {
  font-weight: 600;
}

.single-main-wrap-title {
  margin-bottom: 50px;
}

.single-main-wrap-title h2 {
  font-size: 18px;
}

/*------Booking------------------------------------------------*/
.cart-details-item-header {
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  color: #566985;
  font-size: 14px;
}

.cart-details_text,
.post-article {
  float: left;
  width: 100%;
}

.cart-details_header {
  display: inline-table;
  padding: 15px 20px;
  position: relative;
  border-bottom: 1px solid #eee;
}

.cart-details_text .geodir-category-content-title-item h3 a {
  color: #666;
}

.cart_list li {
  text-align: left;
  padding: 15px 20px;
  color: #7d93b2;
  font-size: 12px;
  border-bottom: 1px solid #eee;
  font-weight: 500;
}

.cart_list li:last-child {
  border-bottom: 0;
}

.cart_list li span,
.cart-total strong {
  float: right;
}

.cart_list li span strong {
  padding-left: 6px;
  color: #7d93b2;
}

.cart-total {
  padding: 15px 20px;
  border-radius: 4px;
  overflow: hidden;
}

.cart-total:before,
.cart-total:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 100%;
}

.cart-total:before {
  left: 20px;
  bottom: -20px;
  opacity: 0.1;
}

.cart-total:after {
  right: 70px;
  top: -10px;
  opacity: 0.2;
}

.cart-details {
  margin-bottom: 15px;
}

.cart-total span {
  float: left;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: 2px;
}

.cart-total strong {
  font-size: 20px;
  font-weight: 400;
}

#progressbar {
  margin-bottom: 30px;
  counter-reset: step;
  width: 100%;
  float: left;
  position: relative;
  box-sizing: border-box;
}

#progressbar li {
  list-style-type: none;
  color: #666;
  font-size: 12px;
  width: 33.3%;
  float: left;
  position: relative;
}

#progressbar li:first-child {
  width: 40px;
  position: absolute;
  left: 0;
  top: 0;
}

#progressbar li span {
  float: right;
  background: #e2e2e2;
  width: 40px;
  height: 40px;
  line-height: 32px;
  color: #fff;
  border-radius: 100%;
  position: relative;
  z-index: 10;
  font-weight: 500;
  border: 4px solid #fff;
  box-sizing: border-box;
  transition: all 200ms linear;
  transition-delay: 0.2s;
  box-shadow: 0 2px 16px rgba(58, 87, 135, 0.15);
}

#progressbar li:after {
  content: "";
  position: absolute;
  bottom: 50%;
  width: 100%;
  margin-bottom: -2px;
  left: 0;
  height: 4px;
  border-radius: 4px;
  background: #e8e8e8;
  z-index: 1;
}

#progressbar li:before {
  content: "";
  position: absolute;
  bottom: 50%;
  margin-bottom: -2px;
  width: 0;
  left: 0;
  height: 4px;
  z-index: 2;
  transition: all 200ms linear;
}

#progressbar li.active:before {
  width: 100%;
}

.bookiing-form-wrap .custom-form form fieldset:not(:first-of-type) {
  display: none;
}

.action-button {
  padding: 12px 30px;
  color: #fff;
  font-size: 12px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.03);
  border: 1px solid #eee;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
}

.next-form {
  float: right;
}

.tr-sec {
  margin-bottom: 0;
}

.success-table-header {
  text-align: left;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0 0 70px;
}

.success-table-header i.decsth {
  color: #5ecfb1;
  position: absolute;
  font-size: 54px;
  left: 0;
  top: 0;
}

.success-table-header h4,
.success-table-header p,
.previous-form {
  float: left;
}

.success-table-header a {
  font-size: 12px;
  color: #fff;
  padding: 12px 25px;
  float: right;
  border-radius: 30px;
  position: relative;
  top: -14px;
}

.success-table-header a i {
  padding-right: 8px;
  font-size: 15px;
}

.log-massage {
  padding: 10px 0;
  text-align: left;
  font-size: 12px;
  color: #666;
}

.bookiing-form-wrap {
  padding: 0 30px 20px;
}

.bookiing-form-wrap .list-single-main-item-title {
  padding: 18px 0px;
}

.bookiing-form-wrap .list-single-main-item-title:before {
  display: none;
}

.cart-details-wrap {
  margin-bottom: 20px;
}

/*------sahre------------------------------------------------*/
.showshare.vis-butsh i:before {
  content: "\f00d";
}

.share-holder {
  position: absolute;
  right: 40px;
  bottom: -50px;
}

.share-holder.hid-share .share-container {
  position: absolute;
  top: -5px;
  right: 0;
  min-width: 170px;
  background: #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.15);
  padding: 8px 8px 8px 18px;
  margin-top: -30px;
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.vissc {
  opacity: 1;
}

.share-holder.hid-share .share-container .share-icon {
  float: left;
  width: 26px;
  height: 26px;
  line-height: 26px;
  position: relative;
  font-size: 13px;
  color: #fff;
  transition: all 0.4s ease-in-out;
}

.share-holder.hid-share .share-container .share-icon:hover {
  color: #fff;
}

.share-holder.hid-share .share-container.visshare {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.share-icon:before {
  font-family: Font Awesome\5 Brands;
}

.share-icon-digg:before {
  content: "\f1a6";
}

.share-icon-email:before {
  content: "\f003";
}

.share-icon-flickr:before {
  content: "\f16e";
}

.share-icon-foursquare:before {
  content: "\f180";
}

.share-icon-rss:before {
  content: "\f09e";
}

.share-icon-facebook:before {
  content: "\f39e";
}

.share-icon-twitter:before {
  content: "\f099";
}

.share-icon-tumblr:before {
  content: "\f173";
}

.share-icon-linkedin:before {
  content: "\f0e1";
}

.share-icon-vimeo:before {
  content: "\f27d";
}

.share-icon-instagram:before {
  content: "\f16d";
}

.share-icon-pinterest:before {
  content: "\f231";
}

.share-icon-googleplus:before {
  content: "\f0d5";
}

.share-icon-in1:before {
  content: "\f08c";
}

.share-icon-stumbleupon:before {
  content: "\f1a4";
}

/*------ testimonilas------------------------------------------------*/
.testi-item {
  transition: all 0.3s ease-in-out;
  transform: scale(0.9);
  opacity: 0.9;
}

.testimonilas-text {
  padding: 75px 50px 75px;
  overflow: hidden;
  background: #f5f6fa;
  border: 1px solid #eee;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  height: 78vh;
}

.testimonilas-text:before,
.testimonilas-text:after {
  font-family: Font Awesome\5 Pro;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: absolute;
  color: #ccc;
  opacity: 0.3;
  font-size: 32px;
  transition: all 400ms linear;
}

.testimonilas-text:after {
  content: "\f10e";
  bottom: 20px;
  right: 25px;
}

.testimonilas-text:before {
  content: "\f10e";
  top: 20px;
  left: 25px;
}

.testimonilas-text .listing-rating {
  float: none;
  display: inline-block;
  margin-bottom: 12px;
}

.testimonilas-avatar h3 {
  font-weight: 700;
  font-size: 20px;
}

.testimonilas-avatar h4 {
  font-weight: 400;
  font-size: 14px;
  color: black !important;
  padding-top: 6px;
}

.testimonilas-carousel .swiper-slide {
  padding: 30px 0;
}

.testi-avatar {
  position: absolute;
  left: 50%;
  top: -30px;
  width: 90px;
  height: 90px;
  margin-left: -45px;
  z-index: 20;
}

.testi-avatar img {
  width: 90px;
  height: 90px;
  float: left;
  border-radius: 100%;
  border: 6px solid #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.1);
}

.swiper-slide-active .testimonilas-text {
  background: #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.1);
}

.testimonilas-text p {
  font-size: 16px !important;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  line-height: 24px;
  padding-bottom: 10px;
  font-weight: 500;
}

.testi-link {
  position: absolute;
  bottom: 0;
  padding: 10px 0;
  border-radius: 10px 10px 0 0;
  background: #f9f9f9;
  border: 1px solid #eee;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.03);
  left: 50%;
  width: 200px;
  margin-left: -100px;
}

.swiper-slide-active .testi-item {
  opacity: 1;
  transform: scale(1);
  background: #fff;
}

.tc-pagination {
  float: left;
  margin-top: 10px;
  width: 100% !important;
}

.tc-pagination_wrap {
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 100%;
}

.tc-pagination2 {
  float: none;
  display: inline-block;
  background: #fff;
  padding: 14px 0;
  border-radius: 30px;
  min-width: 250px;
  border-bottom: 0;
}

.tc-pagination .swiper-pagination-bullet,
.tc-pagination2 .swiper-pagination-bullet {
  opacity: 1;
  background: #ccc;
  margin: 0 2px;
  width: 10px;
  height: 10px;
  transition: all 300ms ease-out;
}

/*------ collage-image------------------------------------------------*/
.collage-image {
  position: relative;
  width: 352px;
  margin: 0 auto;
  height: 300px;
}

.collage-image .main-collage-image {
  position: absolute;
  right: -120px;
  bottom: -120px;
  z-index: 2;
}

.colomn-text-title h3 {
  text-align: left;
  font-size: 34px;
  color: #fff;
  font-weight: 900;
  padding-bottom: 25px;
  font-family: "Raleway", sans-serif;
}

.images-collage-title {
  position: absolute;
  right: -90px;
  width: 180px;
  padding: 12px 0 15px;
  z-index: 10;
  top: 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.icdec:before {
  bottom: -22px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #384f95;
  border-width: 12px;
  margin-left: -32px;
}

.images-collage-title img {
  width: 120px;
  height: auto;
}

.images-collage-title span {
  color: #fff;
}

.colomn-text-title p,
.colomn-text-list li p {
  text-align: left;
  color: #fff;
}

.colomn-text {
  float: left;
  width: 100%;
  position: relative;
  padding: 0 40px 0 0;
}

.pad-top-column-text {
  padding: 80px;
  margin-bottom: 20px;
}

.pad-top-column-text_small {
  padding-top: 40px;
}

.down-btn {
  padding: 16px 55px 16px 70px;
  font-size: 13px;
  font-weight: 400;
  color: #7d93b2;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.4);
  z-index: 10;
  background: #fff;
  float: left;
  position: relative;
  margin: 35px 10px 0 0;
  transition: all 0.4s ease-in-out;
}

.down-btn:hover {
  color: #fff !important;
  background-color: #8ec639 !important;
}

.down-btn i:hover {
  color: white;
}

.down-btn i {
  position: absolute;
  left: 10px;
  top: 50%;
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 100%;
  margin-top: -17px;
  color: #8ec639;
  font-weight: 100;
  font-size: 18px;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.25);
}

.images-collage_icon {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  z-index: 3;
  font-size: 19px;
  color: #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.35);
  animation: bounce 3s ease-in-out infinite;
}

.collage-image-min {
  position: absolute;
  z-index: 10;
}

.collage-image-min:before {
  top: 63px;
  left: 90%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #fff;
  border-width: 12px;
  margin-left: 20px;
  z-index: 20;
}

.collage-image-min.cim_1 {
  bottom: 160px;
  left: 53px;
}

.collage-image-min.cim_2 {
  bottom: 204px;
  right: 200px;
}

.collage-image-min.cim_2 {
  animation: bounce 4s ease-in-out infinite;
}

.collage-image-min.cim_1 {
  animation: bounce 8s ease-in-out infinite;
}

.collage-image-min img {
  position: absolute;
  width: 67px;
  height: 67px;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.35);
}

.collage-image-btn {
  color: #fff;
  position: absolute;
  bottom: 40px;
  right: 120px;
  padding: 12px 25px;
  border-radius: 4px;
  z-index: 20;
  font-size: 12px;
  box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.6);
  transform-style: preserve-3d;
  transform: rotateX(35deg) rotateY(45deg);
}

.collage-image-input {
  position: absolute;
  bottom: 20px;
  left: -90px;
  width: 220px;
  padding: 0 40px 0 16px;
  background: #fff;
  border-radius: 30px;
  z-index: 10;
  text-align: left;
  font-size: 12px;
  color: #999;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.4);
  transform-style: preserve-3d;
  transform: rotateX(35deg) rotateY(45deg);
}

.collage-image-input i {
  position: absolute;
  width: 30px;
  height: 40px;
  line-height: 40px;
  right: 0;
  top: 0;
  animation: bounce 2s ease-in-out infinite;
}

.page-scroll-nav {
  position: fixed;
  top: 35%;
  right: 50px;
  z-index: 50;
  -webkit-transform: translate3d(0, 0, 0);
}

.page-scroll-nav nav {
  width: 40px;
  padding: 18px 5px 8px;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.page-scroll-nav nav li {
  width: 100%;
  margin-bottom: 10px;
}

.page-scroll-nav nav li a {
  display: block;
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  position: relative;
}

.page-scroll-nav nav li a.act-scrlink i {
  color: #fff;
}

.page-scroll-nav nav li a span {
  display: none;
}

.sec-circle {
  z-index: 20;
}

.sec-circle:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 20px;
  border-radius: 30px;
  background: #fff;
  left: 50%;
  top: -10px;
  margin-left: -50px;
}

.sec-circle_gray:before {
  background: #f6f6f6;
}

/*------ price tables------------------------------------------------*/
.price-item {
  float: left;
  width: 33.3%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
}

.price-item:hover,
.best-price {
  box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.07);
  z-index: 2;
}

.best-price {
  top: -30px;
}

.price-item:hover {
  z-index: 3;
}

.price-head {
  float: left;
  width: 100%;
  padding: 32px 20px 80px;
  position: relative;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.price-head .footer-wave {
  bottom: -12px;
  left: 0;
  height: 100px;
  opacity: 1;
  z-index: 10;
}

.price-head .footer-wave.footer-wave2 {
  opacity: 0.3;
  bottom: 10px;
}

.price-head h3 {
  color: #fff;
  position: relative;
  z-index: 2;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid rgba(255, 255, 255, 0.41);
  display: inline-table;
  padding: 10px 40px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.11);
  overflow: hidden;
}

.price-head-decor {
  position: absolute;
  bottom: 70px;
  left: 20px;
  color: #fff;
  opacity: 0.2;
  font-size: 54px;
}

.price-num {
  padding: 10px 15px 0;
  overflow: hidden;
}

.price-num:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -35px;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 100%;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
}

.price-num span.curen {
  position: relative;
  top: -32px;
  padding-right: 7px;
  font-weight: 400;
  font-size: 19px;
}

.price-num-item {
  font-size: 65px;
  position: relative;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.81);
  display: inline-table;
}

.price-desc ul li {
  float: left;
  width: 100%;
  padding: 18px 0;
  color: #7d93b2;
  font-weight: 500;
  font-size: 12px;
  border-top: 1px solid #eee;
}

.pri ce-desc ul li:nth-child(2n + 1) {
  background: #f9f9f9;
}

.price-desc ul li:last-child {
  border-bottom: 1px solid #eee;
}

.price-desc ul li:first-child {
  border-top: none;
}

.price-link {
  display: inline-table;
  padding: 16px 30px;
  color: #fff;
  border-radius: 6px;
  font-size: 11px;
  position: relative;
  margin: 20px 0 30px;
  font-weight: 400;
}

.price-link:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  opacity: 0;
  border-radius: 100%;
  right: 20px;
  bottom: 30px;
  transition: all 0.4s ease-in-out;
  transform: scale(0);
}

.price-link:hover:before {
  opacity: 0.2;
  transform: scale(4);
}

.price-content {
  background: #fff;
  border: 1px solid #eee;
  border-top: none;
  overflow: hidden;
  z-index: 20;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
}

.pricing-wrap {
  margin: 60px 0 30px;
}

.pricing-switcher {
  text-align: center;
  position: relative;
}

.pricing-switcher .fieldset {
  display: inline-block;
  position: relative;
  padding: 2px;
  border-radius: 6px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.07);
}

.pricing-switcher input[type="radio"] {
  position: absolute;
  opacity: 0;
  left: -1000px;
}

.pricing-switcher label {
  position: relative;
  z-index: 1;
  display: inline-block;
  float: left;
  width: 210px;
  height: 46px;
  line-height: 46px;
  cursor: pointer;
  font-weight: 600;
  font-size: 11px;
  color: #fff;
  transition: all 400ms ease-out;
}

.pricing-switcher .switch {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 40px;
  width: 210px;
  background: #fff;
  border-radius: 6px;
  transition: all 300ms ease-out;
}

.pricing-switcher input[type="radio"]:checked+label+.switch,
.pricing-switcher input[type="radio"]:checked+label:nth-of-type(n)+.switch {
  -webkit-transform: translateX(204px);
  transform: translateX(204px);
}

.pricing-switcher input[type="radio"]:checked+label {
  color: #7d93b2;
}

.price-num span.curen {
  position: relative;
  top: -32px;
  padding-right: 7px;
  font-weight: 400;
  font-size: 17px;
}

.price-num-desc {
  color: #fff;
  display: inline-table;
  font-size: 13px;
  font-weight: 400;
  position: relative;
  overflow: hidden;
}

.mouth-cont {
  z-index: 1;
  opacity: 1;
  position: relative;
}

.year-cont {
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  z-index: 2;
}

.year-cont,
.year-sale,
.price-item {
  transition: all 300ms ease-out;
}

.year-sale {
  position: absolute;
  top: 60px;
  right: 30px;
  font-size: 36px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.41);
  opacity: 0;
}

.year-mont .year-sale {
  opacity: 1;
  top: 80px;
}

.year-mont .mouth-cont {
  opacity: 0;
}

.year-mont .year-cont {
  opacity: 1;
}

.year-mont .price-num-item .year-cont {
  left: -30px;
}

.recomm-price {
  margin: 0 0 20px;
  font-weight: 400;
  color: #7d93b2;
  font-size: 12px;
  position: absolute;
  left: 50%;
  bottom: -20px;
  margin-left: -90px;
  width: 180px;
  background: #f9f9f9;
  padding: 15px 0;
  border-radius: 20px 20px 0 0;
  border: 1px solid #eee;
  box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.05);
}

.recomm-price i {
  margin-right: 10px;
}

.rec-link {
  margin-bottom: 85px;
}

.align-titile h4 {
  position: relative;
  top: 30px;
  text-align: left;
  font-weight: 600;
  color: #7d93b2;
  font-size: 19px;
  display: block;
}

.client-item {
  padding: 0 20px;
  display: block;
}

.client-item img {
  width: 100%;
  height: auto;
  transition: all 300ms linear;
}

.cc-btn {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin-top: -18px;
  border-radius: 100%;
  background: #fff;
  border: 1px solid #eee;
  box-sizing: border-box;
  z-index: 20;
  cursor: pointer;
}

.clients-carousel-wrap {
  padding: 0 20px;
}

.cc-btn.cc-next {
  right: 0;
}

.cc-btn.cc-prev {
  left: 0;
}

/*------features------------------------------------------------*/
.absolute-wrap {
  margin-top: -150px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.06);
  padding: 10px 30px 40px;
}

section.absolute-wrap_section {
  padding-bottom: 40px;
  z-index: 10;
}

section.absolute-wrap_section .section-separator {
  top: 20px;
}

.features-box-container {
  margin-top: 30px;
}

.custom-line {
  width: 305px;
  height: 3px;
  background-color: black;
  margin-top: 53px;
  margin-left: 454px;
}

h3 {
  font-family: "Quicksand", sans-serif !important;
}

h2 {
  font-family: "Quicksand", sans-serif !important;
}

h1 {
  font-family: "Quicksand", sans-serif !important;
  text-align: left;
}

.inner-static {
  background-color: white;
  padding: 10px;
}

.breadcrumb {
  display: flex;
  gap: 10px;
}

.main-content-area {
  margin-top: 100px;
  background-color: #f6f8fa;
}

.block-top-title {
  padding: 10px;
}

.features-box {
  padding: 25px 10px 25px 100px;
  overflow: hidden;
  position: relative;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eee;
}

.absolute-wrap .features-box {
  box-shadow: none;
  padding: 25px 15px;
}

.features-box .time-line-icon {
  position: absolute;
  left: 10px;
  top: 20px;
  width: 70px;
  height: 70px;
}

.absolute-wrap .features-box .time-line-icon {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.features-box .time-line-icon i {
  font-size: 40px;
}

.features-box h3 {
  text-align: left;
  font-weight: 600;
  color: #7d93b2;
  font-size: 18px;
  padding-bottom: 12px;
}

.features-box p {
  text-align: left;
  color: #878c9f;
  font-size: 12px;
  line-height: 22px;
}

.absolute-wrap .features-box h3,
.absolute-wrap .features-box p {
  text-align: center;
}

.time-line-icon {
  position: relative;
  margin-bottom: 20px;
  /* background: url(../images/clouds.png) no-repeat center; */
}

.features-box.gray-bg,
.absolute-wrap .features-box:hover {
  background: #f5f6fa;
}

.big_prom {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  margin-top: 50px;
  font-size: 13px;
}

.video_section-title h4 {
  font-size: 14px;
  color: #fff;
  padding-bottom: 20px;
}

.video_section-title h2 {
  font-size: 32px;
  font-weight: 800;
  color: #fff;
  font-family: "Raleway", sans-serif;
}

.ab_text {
  padding-left: 20px;
}

.ab_text-title h3 {
  font-weight: 700;
  text-align: left;
  font-size: 24px;
  font-family: "Raleway", sans-serif;
}

.ab_text-title h4 {
  font-size: 14px;
  padding: 10px 0 0;
  font-weight: 400;
  color: #7d93b2;
  text-align: left;
}

.no-padding-section {
  padding: 0;
}

/*------Timeline------------------------------------------------*/
.process-wrap li {
  float: left;
  width: 33.3%;
  padding: 0 30px;
  position: relative;
}

.process-wrap_half li {
  width: 50%;
}

.process-item {
  float: left;
  width: 100%;
  position: relative;
  border: 1px solid #eee;
  padding: 30px 20px 50px;
  border-radius: 6px;
  z-index: 20;
  background: #f9f9f9;
}

.process-wrap li i {
  margin: 0 auto 0;
  font-size: 59px;
}

.process-wrap li h4 {
  text-align: center;
  float: left;
  width: 100%;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 18px;
  color: #566985;
}

.process-wrap p {
  text-align: center;
  color: #7d93b2;
  font-size: 13px;
  line-height: 22px;
}

.process-count {
  position: absolute;
  z-index: 10;
  font-size: 14px;
  font-weight: bold;
  left: 50%;
  margin-left: -25px;
  bottom: -25px;
  background: #fff;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #666;
  border: 1px solid #eee;
  box-shadow: 0 9px 16px rgba(58, 87, 135, 0.15);
}

.pr-dec {
  position: absolute;
  top: 50%;
  right: -50px;
  width: 150px;
  height: 1px;
  border-bottom: 1px solid #e2e2e2;
}

.process-end {
  display: inline-block;
  margin-top: 60px;
  position: relative;
}

.process-end:before {
  content: "";
  position: absolute;
  top: -60px;
  left: 50%;
  width: 1px;
  height: 70px;
  background: #e2e2e2;
  z-index: -1;
}

.process-end i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #fff;
  border-radius: 100%;
  z-index: 3;
  background: #5ecfb1;
  border-radius: 100%;
  font-size: 20px;
  color: #fff;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 10px #f7f7f7;
}

.hidden-section {
  overflow: hidden;
}

.process-item_time-line {
  margin-bottom: 50px;
}

.process-item_time-line:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  top: 28px;
  z-index: 1;
  background: #e9e9e9;
}

.process-item_time-line:after {
  font-family: Font Awesome\5 Pro;
  content: "\f00c";
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  right: 20px;
  top: 8px;
  border-radius: 100%;
  font-size: 18px;
  color: #fff;
  animation: pulse3 3.1s infinite ease-in-out;
  z-index: 2;
  display: none;
}

.process-item_time-line:last-child:after {
  display: block;
}

@keyframes pulse3 {
  50% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2),
      0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 40px rgba(238, 238, 238, 0.4);
  }
}

.process-item_time-line {
  float: left;
  width: 33.3%;
  position: relative;
  z-index: 2;
  padding: 10px 20px;
}

.pi-text {
  background: #fff;
  border-radius: 6px;
  padding: 20px 20px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.06);
}

.pi-text h4 {
  text-align: left;
  padding-bottom: 10px;
  font-size: 16px;
}

.pi-text p {
  text-align: left;
  color: #878c9f;
  font-size: 12px;
  line-height: 24px;
  padding-bottom: 10px;
}

.pi_head {
  float: left;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 40px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.13);
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  z-index: 20;
  position: relative;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 0;
  opacity: 0.6;
}

/*------404------------------------------------------------*/
.error-wrap {
  padding: 40px 0;
}

.error-wrap h2 {
  font-size: 154px;
  color: #fff;
  font-weight: 900;
  font-family: "Raleway", sans-serif;
  letter-spacing: 10px;
}

.error-wrap p {
  color: rgba(255, 255, 255, 0.91);
  font-size: 14px;
}

.error-wrap form {
  margin: 30px auto 20px;
  max-width: 550px;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}

.error-wrap form input {
  float: left;
  width: 100%;
  border: none;
  background: #fff;
  height: 50px;
  padding: 0 100px 0 20px;
  z-index: 1;
}

.error-wrap form .search-submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 50px;
  line-height: 50px;
  z-index: 2;
  color: #fff;
  border: none;
  cursor: pointer;
}

.error-wrap form .search-submit:hover {
  background: #2c3b5a;
}

.error-wrap a.btn {
  display: inline-table;
  margin-top: 10px;
}

#contact-form #message {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  text-align: left;
}

.mob-nav-content-btn {
  padding: 15px 0;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 15px;
  display: none;
}

.mncb_half {
  width: 50%;
  margin-top: 30px;
  border-radius: 0 6px 6px 0;
  box-sizing: border-box;
}

.mncb_half:first-child {
  border-radius: 6px 0 0 6px;
  border-right: 1px solid rgba(0, 0, 0, 0.21);
}

.mob-nav-content-btn.slsw_vis i {
  color: #fff;
}

.mob-nav-content-btn i {
  margin-right: 12px;
}

.map-close {
  position: absolute;
  left: 30px;
  top: 10px;
  z-index: 1100;
  cursor: pointer;
  width: 43px;
  height: 43px;
  line-height: 43px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  display: none;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.close_sbfilters {
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 20;
  cursor: pointer;
  color: #fff;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #eee;
  border-right: none;
  background: #f9f9f9;
  display: none;
  border-radius: 30px 0 0 30px;
}

/*------responsive------------------------------------------------*/
@media only screen and (max-width: 1290px) {
  .main-header {
    padding: 0 20px;
  }

  .header-search_btn {
    margin-left: 30px;
  }

  .nav-holder {
    margin-left: 0px;
  }

  .footer-wave {
    bottom: -240px;
  }

  .header-search_btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    border-radius: 100%;
    top: 22px;
  }

  .header-search_btn i {
    margin-right: 0;
  }

  .header-search_btn span,
  .header-search_container:before {
    display: none;
  }

  .price-head .footer-wave {
    bottom: -18px;
  }
}

@media only screen and (max-width: 1070px) {

  .nav-button-wrap,
  .mob-bg,
  .mob-nav-content-btn,
  .map-close,
  .close_sbfilters {
    display: block;
  }

  .header-search-input,
  .header-search-input.header-search_selectinpt {
    margin-bottom: 10px;
  }

  #footer-twiit .timePosted a,
  .header-search-input,
  .header-search-input.header-search_selectinpt,
  .menusb,
  .main-menu .menusb ul,
  .menusb li,
  .faq-nav,
  .col-list-wrap,
  .menusb a,
  .subscribe-widget {
    width: 100%;
  }

  .faq-nav,
  .list-main-wrap-header.anim_clw,
  .menusb,
  .main-menu .menusb ul,
  .menusb li,
  .menusb a,
  .subscribe-widget {
    float: left;
  }

  .col-list-wrap.anim_clw {
    padding-top: 0;
  }

  .col-list-wrap.anim_clw.hidsb_act {
    left: 380px !important;
  }

  .list-main-wrap-header.anim_clw.hidsb_act {
    left: 0 !important;
  }

  .list-main-wrap-header.anim_clw {
    position: relative;
    width: 100%;
    top: 0;
  }

  .header-search_container {
    bottom: inherit;
    top: 95%;
  }

  .vis-head-search {
    bottom: inherit;
    top: 100%;
  }

  .ntm {
    margin-top: 0;
  }

  .main-menu nav ul,
  .main-footer .footer-wave,
  .counter-widget:before,
  .back-tofilters,
  .lws_mobile,
  .show-hidden-sb,
  .top-breadcrumbs {
    display: none;
  }

  .back-to-filters {
    display: none !important;
  }

  .map-container.column-map.right-pos-map,
  .map-container.fw-map.big_map.hid-mob-map {
    width: 100%;
    right: -100%;
    position: absolute;
    z-index: 100 !important;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .fixed-mobile {
    position: fixed !important;
    top: 80px !important;
  }

  .main-menu {
    position: absolute;
    top: 80px;
    right: -20px;
    width: 300px;
    z-index: 50;
    padding: 15px 10px;
    background: #fff;
    border-radius: 0 0 0 6px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.07);
  }

  .vismobmenu {
    opacity: 1;
    right: 0;
    visibility: visible;
  }

  .menusb {
    display: block;
    position: relative;
    overflow: hidden;
  }

  .main-menu .menusb ul {
    display: block;
    position: relative;
  }

  .menusb li {
    margin: 0;
    position: relative;
    overflow: hidden;
    padding: 0 20px;
  }

  .menusb li i {
    position: absolute;
    right: 18px;
    top: 10px;
  }

  .menusb a {
    padding: 8px 5px;
    position: relative;
    font-weight: 600;
    font-size: 12px;
    color: #50596e;
    line-height: 14px;
    text-align: left;
  }

  .menusb a.act-link {
    color: #4db7fe;
  }

  .menusb a i {
    transform: rotate(-90deg);
  }

  .menusb a.back {
    padding-left: 24px;
  }

  .menusb a.back:before {
    font-family: Font Awesome\5 Pro;
    content: "\f177";
    font-size: 14px;
  }

  .logo-holder {
    top: 8px;
  }

  section.hero-section {
    padding: 50px 0 80px;
    z-index: 20;
  }

  .intro-item h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .main-search-input-tabs {
    margin-top: 30px;
  }

  .gallery-item,
  .three-columns-grid .listing-item {
    width: 50%;
  }

  .collage-image {
    margin-top: 280px;
  }

  .subscribe-widget {
    margin-top: 20px;
  }

  .footer-social,
  .footer-link {
    margin-top: 10px;
  }

  .footer-widget-posts li:last-child {
    margin-bottom: 0;
  }

  .features-box-container .features-box {
    margin-bottom: 10px;
  }

  .footer-inner {
    padding: 30px 0 50px;
    overflow: hidden;
  }

  .footer-logo,
  .faq-nav,
  .footer-social,
  .footer-link {
    margin-bottom: 20px;
  }

  .hero-slider-item {
    padding: 50px 0 150px;
  }

  .shb {
    top: 100%;
    margin-top: -100px;
  }

  .hero-map-search-wrap {
    margin-top: 0;
    padding: 0;
  }

  .pagination,
  .bookiing-form-wrap {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .col-list-wrap .pagination,
  .fwmpag {
    margin-bottom: 0;
    margin-top: 20px;
  }

  .post-container .post-article {
    margin-bottom: 0;
  }

  section.parallax-section {
    padding: 80px 0;
  }

  .list-single-header-column {
    margin-top: 22px;
  }

  .inline-facts-wrap {
    margin-bottom: 10px;
  }

  .listing-carousel-wrap {
    height: 400px;
  }

  .listing-carousel-wrap .listing-carousel-button {
    margin-top: -25px;
  }

  .counter-widget {
    width: 100%;
    margin: 20px 0;
    top: 0;
  }

  .cws,
  .post-article .btn {
    float: left;
    margin-top: 10px;
  }

  .post-article .btn {
    margin-top: 20px;
  }

  .cws li {
    margin-left: 0;
    margin-right: 20px;
  }

  .listing-hero-section {
    padding: 150px 0 0;
  }

  .price-head .footer-wave {
    bottom: -28px;
  }

  .page-scroll-nav {
    right: 0;
  }

  .page-scroll-nav nav {
    border-radius: 20px 0 0 20px;
  }

  .to-top,
  .chat-widget-button,
  .chat-widget_wrap {
    right: 10px;
  }

  .header-user-menu {
    top: 22px;
  }

  .main-header:before {
    width: 50% !important;
  }

  .list-main-wrap-title h2 {
    font-size: 13px;
    top: 12px;
  }

  .login-column {
    width: 100%;
    padding-top: 60px;
    min-height: auto;
  }

  .login-column-bg {
    position: relative;
    height: auto;
    padding: 150px 0;
    left: 0;
    width: 100%;
  }

  .login-promo-container {
    position: relative;
    top: 0;
    float: left;
  }
}

@media only screen and (max-width: 768px) {
  .header-modal {
    right: 10px;
  }

  .vis-wishlist {
    right: 0;
  }

  .add-list,
  .review-comments-avatar:before {
    display: none;
  }

  .main-search-input-item {
    width: 100%;
    padding-bottom: 10px;
  }

  .main-search-input-item {
    border-right: none;
  }

  .main-search-input-item:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    height: 1px;
    z-index: 1100;
    background: #eee;
  }

  .main-search-input-item .clear-singleinput {
    right: 28px;
  }

  .main-search-input-wrap .main-search-input-item:last-child:before,
  .scroll-nav,
  .subfooter-nav:before,
  .process-item_time-line:last-child:after {
    display: none;
  }

  .main-search-button {
    position: relative;
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    margin-top: 10px;
  }

  .main-search-input {
    padding: 8px 15px;
  }

  .gallery-item,
  .process-wrap li,
  .rooms-media,
  .rooms-details,
  .restmenu-item,
  .sub-footer .copyright,
  .subfooter-nav,
  .best-price,
  .price-item,
  .listing-item,
  .team-box,
  .price-item,
  .post-nav,
  .list-single-stats,
  .process-item_time-line {
    width: 100%;
  }

  .rooms-details {
    padding: 25px 0 10px 0;
  }

  .process-wrap li,
  .process-item_time-line:last-child {
    margin-bottom: 50px;
  }

  .process-wrap li:last-child {
    margin-bottom: 0;
  }

  .subfooter-nav {
    margin-top: 10px;
    text-align: left;
  }

  .sub-footer .copyright,
  .subfooter-nav,
  .best-price,
  .price-item,
  .listing-item {
    top: 0;
  }

  .sub-footer .copyright {
    margin-bottom: 10px;
  }

  .subfooter-nav li {
    margin-left: 0;
    margin-right: 15px;
  }

  .sub-footer .lang-wrap,
  .subfooter-nav {
    margin-top: 10px;
  }

  .team-box,
  .price-item,
  .process-item_time-line {
    margin-bottom: 10px;
  }

  .single-facts .inline-facts-wrap {
    width: 50%;
  }

  .reviews-comments-item {
    padding: 20px 0 20px 0;
  }

  .review-comments-avatar {
    top: -10px;
    left: 10px;
    z-index: 50;
  }

  .review-comments-avatar img,
  .review-comments-avatar {
    width: 40px;
    height: 40px;
  }

  .reviews-comments-header {
    margin-bottom: 0;
  }

  .scroll-nav-wrapper-opt {
    top: 0;
  }

  .scroll-nav-wrapper {
    padding: 15px 0;
  }

  .scroll-nav-wrapper-opt a.scroll-nav-wrapper-opt-btn {
    margin-left: 0;
    margin-right: 12px;
  }

  .list-single-stats {
    padding-top: 15px;
  }

  .list-single-stats li {
    margin-left: 0;
    margin-right: 10px;
  }

  .list-single-stats,
  .scroll-nav-wrapper-opt,
  .sub-footer .lang-wrap,
  .subfooter-nav,
  .main-search-button {
    float: left;
  }

  .pi_head {
    margin-bottom: 20px;
  }

  .header-user-menu {
    top: 24px;
    margin-left: 44px;
  }

  .header-user-menu span,
  .header-user-menu span img {
    width: 30px;
    height: 30px;
  }

  .header-user-menu span {
    left: -44px;
    top: 4px;
  }

  .header-user-name:before {
    bottom: 0px;
  }

  .header-user-menu:after {
    left: -60px;
  }

  .header-user-name {
    font-size: 10px;
  }
}

@media only screen and (max-width: 650px) {
  .header-user-menu:after {
    left: -54px;
  }

  .listing-hero-section {
    padding: 90px 0 0;
  }

  .header-search-input-wrap {
    padding: 0;
  }

  .reviews-score-wrap {
    padding: 10px 20px 25px;
  }

  .review-score-detail {
    padding-left: 100px;
  }

  .header-search_btn {
    margin-left: 10px;
    background: none;
    top: 24px;
  }

  .header-search_btn i {
    font-size: 16px;
  }

  .main-header .lang-wrap,
  .bold-facts .inline-facts-wrap:before,
  .pagination a.prevposts-link,
  .pagination a.nextposts-link,
  .grid-opt {
    display: none;
  }

  .show-reg-form {
    margin-right: 20px;
  }

  .cart-btn {
    margin-right: 0;
  }

  .main-header {
    padding: 0 15px;
  }

  .logo-holder {
    top: 8px;
    height: 25px;
  }

  .header-modal {
    width: 340px;
  }

  .hero-categories li {
    padding: 0 20px;
  }

  .hero-categories li a i {
    font-size: 24px;
  }

  .listing-carousel-button {
    margin-top: -75px;
  }

  .single-facts .inline-facts-wrap,
  .review-total,
  .down-btn,
  .review-range-container,
  .three-columns-grid .listing-item,
  .menu-filters,
  .menu-filters a,
  .listing-filters a,
  .header-search-button {
    width: 100%;
  }

  .header-search-button {
    position: relative;
    float: left;
    margin-top: 12px;
    border-radius: 6px;
  }

  .review-range-container {
    padding-right: 0;
  }

  .review-total {
    left: 0;
    top: 0;
    border-radius: 38px;
  }

  .user-profile-header-avatar {
    top: -250px;
  }

  .user-profile-header_media_title {
    padding-left: 30px;
  }

  .user-profile-header_stats {
    float: left;
    padding-left: 30px;
    margin-bottom: 15px;
  }

  .listing-filters a {
    padding: 12px 20px;
  }

  .video_section-title h2 {
    font-size: 24px;
  }

  .collage-image {
    margin-top: 380px;
  }

  .down-btn {
    padding: 16px 0;
  }

  .pad-top-column-text {
    padding: 20px;
    margin-bottom: 20px;
  }

  .post-opt li {
    margin-right: 10px;
  }

  .listing-carousel-wrap {
    height: 280px;
  }

  .list-single-header-item .geodir-category-location a {
    margin-top: 6px;
  }

  .daterangepicker_big .daterangepicker {
    width: 300px !important;
    z-index: 5000;
  }

  .menu-filters a,
  .listing-filters a {
    float: left;
    display: block;
    border: none;
  }

  .menu-filters a,
  .listing-filters a {
    border-top: 1px solid #eee;
  }

  .listing-filters a:last-child {
    border-radius: 0px;
    border-right: none;
  }

  .listing-filters {
    border: 1px solid #eee;
    border-radius: 6px;
    overflow: hidden;
  }

  .menu-filters a:first-child,
  .listing-filters a:first-child {
    border-top: none;
  }

  .pricing-switcher label,
  .pricing-switcher .fieldset {
    width: 100%;
  }

  .pricing-switcher .switch {
    right: 5px;
    width: auto;
  }

  .pricing-switcher input[type="radio"]:checked+label+.switch,
  .pricing-switcher input[type="radio"]:checked+label:nth-of-type(n)+.switch {
    transform: translateX(0) translateY(44px);
  }

  .listing-item,
  .listing-item:nth-child(even) {
    padding: 0;
  }

  .list-main-wrap-title {
    width: 100%;
  }

  .list-main-wrap-opt {
    float: left;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 460px) {
  .main-search-input-tabs .tabs-menu li a {
    padding: 12px 10px;
  }

  .hero-categories li {
    padding: 0 10px;
  }

  .listing-filters a {
    padding: 10px 15px;
    font-size: 11px;
  }

  .rate-review {
    float: left;
    margin-top: 22px;
  }

  .list-single-header_bottom .geodir_status_date {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 360px) {
  .nav-button-wrap {
    margin-right: 10px;
  }

  .show-reg-form {
    margin-right: 10px;
  }

  .show-reg-form i {
    display: none;
  }

  .header-search_btn {
    float: right;
  }

  .logo-holder {
    top: 8px;
    height: 20px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .bg {
    background-attachment: scroll !important;
    -webkit-background-size: cover;
    background-position: center;
  }
}

.nice-select .list::-webkit-scrollbar {
  width: 12px;
  background: #eee;
}

.nice-select .list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
}

/* Wallet Page Code Start */

.walletBox {
  padding: 16px;
  /* Assuming theme.spacing(2) is 16px */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
  /* Assuming a default borderRadius value of 4px */
  margin-bottom: 16px;
  /* Assuming theme.spacing(2) is 16px */
}

.walletLabel {
  font-size: 0.875rem;
  color: #757575;
  /* Assuming theme.palette.text.secondary is a shade of grey, #757575 for example */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 8px;
  /* Assuming theme.spacing(1) is 8px */
}

.table th {
  background-color: #eeeeee;
  /* Assuming theme.palette.grey[200] is a light grey, #eeeeee for example */
}

.labelSuccess {
  color: #fff;
  background-color: #4caf50;
  /* Assuming theme.palette.success.main is a shade of green, #4caf50 for example */
  padding: 4px 8px;
  /* Assuming theme.spacing(0.5) is 4px and theme.spacing(1) is 8px */
  border-radius: 4px;
  /* Assuming a default borderRadius value of 4px */
}

/* Wallet Page Code End */


.modal {
  border-radius: 20px;
  /* display: flex; */
  position: fixed;
  z-index: 100;
  left: 28%;
  top: 30%;
  width: 43%;
  height: 20%;
  background-color: #dedddd;
  /* overflow: auto; */
  /* background-color: rgb(186 182 182); */
  padding-top: 60px;
  justify-content: center;
}

.google_map:first-child {
  height: 80vh !important;
  width: 100% o !important;
  overflow: visible !important;
}


.custom-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  /* Background color of the button */
  color: #3b4249;
  /* Text color */
  border: none;
  padding: 7px 5px;
  width: 300px;
  /* border-radius: 5px; */
  cursor: pointer;
  font-size: 16px;
}

.icon {
  margin-right: 10px;
  /* Space between the icon and the text */
}






/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

.modal-dialog {
  padding-top: 100px;
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none;
}

.modal-dialog.show {
  transform: translateY(0);
}

.modal-dialog.modal-static {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 20px);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 20px);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border-radius: 5px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
  margin: auto;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
}

.modal-header .btn-close {
  padding: 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #dee2e6;
}

.modal-footer>* {
  margin: 0.25rem;
}

.modal-sm {
  max-width: 300px;
}

.modal-lg {
  max-width: 800px;
}

.modal-xl {
  max-width: 1140px;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.lg-backdrop {
  z-index: 10000 !important;
  background-color: rgba(0, 0, 0, .8) !important;
}

.lg-outer {
  z-index: 10500 !important;
}

.custom-nav-tabs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 12px;
}

.team-box-swiper {
  width: 100%;
}

.custom-nav-item {
  margin-right: 1rem;
}

.custom-nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #000;
  border: 1px solid transparent;
  transition: border-color 0.3s;
}

.custom-nav-link {
  border-color: gray;
  border-radius: 24px;
}

.custom-nav-link.active {
  border-color: black;
  font-weight: bold;
  border-radius: 24px;
}

.custom-tab-pane {
  display: none;
}

.custom-tab-pane.active {
  display: block;
}

.swiper-button-prev {
  display: none !important;
}

.swiper-button-next {
  display: none !important;
}

.button-wrapper button {
  background: no-repeat;
  border: none;
  outline: none;
  border: 1px solid gray;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.text-white {
  color: white;
}

.text-start {
  text-align: start;
}




a {
  text-decoration: none;
  color: #fff;
  /* text-transform: uppercase; */
}

#UniqueMenuToggle {
  display: none;
}

/* Left sidebar */


/* For Navbar */
.unique-logo {
  display: block;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  letter-spacing: .3px;
  padding: 20px 0px;
  background: #000831;
}

.unique-nav-items {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.unique-nav-items a {
  display: block;
  padding: 20px 0px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  transition: all .4s ease;
}

.unique-nav-items a:hover,
.unique-nav-items a.unique-active {
  background: #000831;
}

/* Right main content */
.unique-right {
  position: relative;
  width: 100%;
  height: 100%;
  /* padding: 30px 30px; */
  overflow-x: hidden;
  display: flex;
  align-items: center;
  transition: all .8s ease;
  z-index: 900;
  /* Ensure it is below the sidebar */
}

.unique-content {
  width: 100%;
  background: #f6f8fa;
}



/* Toggle bar */
.unique-toggle-icon {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1100;
  /* Ensure it is above the sidebar */
}

.unique-toggle-icon .unique-line {
  width: 24px;
  height: 3px;
  background-color: #000831;
  margin: 6px 0;
  display: block;
  border-radius: 8px;
}

.unique-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background: white;
  border: 1px solid gray;
  opacity: 1;
  transform: translateX(100%);
  transition: all 0.8s ease;
  z-index: 10000;
}

/* If Checked */
#UniqueMenuToggle:checked~.unique-sidebar {
  transform: translate(0);
}

td {
  padding: 10px;
  text-align: left;
  font-size: 20px;
}




.geodir-js-favorite_btn02 {
  position: absolute;
  left: 6px;
  padding-top: 7px;
  top: 2px;
  z-index: 20;
  cursor: pointer;
  color: #fff;
}

.geodir-js-favorite_btn02 i {
  float: left;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 100%;
  background: rgb(175, 35, 34);
  position: relative;
  z-index: 2;
}

.geodir-js-favorite_btn02 span {
  border-radius: 36px;
  background: rgb(175, 35, 34);
  float: left;
  left: -36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  padding: 0 18px 0 48px;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  margin-top: -2px;
  visibility: hidden;
}

.geodir-js-favorite_btn02 span {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.geodir-js-favorite_btn02 i {
  font-weight: 900;
}

.geodir-js-favorite_btn020 {
  /* position: absolute; */
  left: 6px;
  padding-top: 7px;
  top: 2px;
  z-index: 20;
  cursor: pointer;
  color: #fff;
}

.geodir-js-favorite_btn020 i {
  float: left;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 100%;
  background: rgb(142, 198, 57);
  position: relative;
  z-index: 2;
}

.geodir-js-favorite_btn020 span {
  border-radius: 36px;
  background: rgb(142, 198, 57);
  float: left;
  left: -36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  padding: 0 18px 0 48px;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  margin-top: -2px;
  visibility: hidden;
}

.geodir-js-favorite_btn020 span {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.geodir-js-favorite_btn020 i {
  font-weight: 900;
}

.geodir-js-favorite_btn01 {
  position: absolute;
  left: 6px;
  padding-top: 7px;
  top: 2px;
  z-index: 20;
  cursor: pointer;
  color: #fff;
}

.geodir-js-favorite_btn01 i {
  float: left;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 100%;
  background: rgb(175, 35, 34);
  position: relative;
  z-index: 2;
}

.geodir-js-favorite_btn01 span {
  border-radius: 36px;
  background: rgb(175, 35, 34);
  float: left;
  left: -36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  padding: 0 18px 0 48px;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  margin-top: -2px;
  visibility: hidden;
}

.geodir-js-favorite_btn01 span {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.geodir-js-favorite_btn01 i {
  font-weight: 900;
}

.geodir-js-favorite_btn010 {
  left: 6px;
  padding-top: 7px;
  top: 2px;
  z-index: 20;
  cursor: pointer;
  color: #fff;
}

.geodir-js-favorite_btn010 i {
  float: left;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 100%;
  background: rgb(175, 35, 34);
  position: relative;
  z-index: 2;
}

.geodir-js-favorite_btn010 span {
  border-radius: 36px;
  background: rgb(175, 35, 34);
  float: left;
  left: -36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  padding: 0 18px 0 48px;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  top: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  margin-top: -2px;
  visibility: hidden;
}

.geodir-js-favorite_btn010 span {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.geodir-js-favorite_btn010 i {
  font-weight: 900;
}

.email_place::placeholder {
  color: #ff0000c7 !important;
}

.mr-5 {
  margin-right: 5px;
}

.btn-1 {
  display: inline-block;
  /* padding: 8px 20px; */
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}


.gm-control-active {

  border-radius: 4px !important;
}

.gm-svpc {
  border-radius: 4px !important;
}

.text-black {
  color: black !important;
}

/* .loader-junaid{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 10px solid white;
  border-top: 10px solid #000;
  animation: rotate 0.7s infinite;
}
@keyframes rotate{
  100%{
    rotate: 360deg;
  }
} */